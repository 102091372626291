import {Controller, useForm} from 'react-hook-form'
import React, {useState, useEffect} from 'react'
import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose,
  buttonStyleOnDrawer,
  drawerStyle,
  textInputStyle,
  textStyleBold
} from '../assets/styles/styles'

import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

export const DrawerLogin = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm()
  const [onLogin, setOnLogin] = useState(false)

  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('warning')

  const doLogin = async (data) => {
    props.hookBackdrop[1](true)
    setOpenAlert(false)
    setOnLogin(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/subscribers/signIn`, data, {timeout: process.env.REACT_APP_AXIOS_TIMEOUT})
      let error = response.data.error
      let message = response.data.message
      let object = response.data.object
      let token = response.data.token
      if (error) {
        setAlertMessage(message)
        setAlertSeverity('warning')
        setOpenAlert(true)
        setOnLogin(false)
      } else {
        localStorage.setItem('token', token)
        localStorage.setItem('userID', object.id)
        localStorage.setItem('firstName', object.firstName)
        localStorage.setItem('lastName', object.lastName)
        localStorage.setItem('email', object.email)
        localStorage.setItem('phone', object.phone)
        localStorage.setItem('tier', object.tier)
        localStorage.setItem('tierStart', object.tierStart)
        localStorage.setItem('tierEnds', object.tierEnds)
        localStorage.setItem('validated', object.validated)
        localStorage.setItem('photoId', object.photoId)
        localStorage.setItem('status', object.status)
        localStorage.setItem('n_pets', object.n_pets)
        localStorage.setItem('n_meds', object.n_meds)
        localStorage.setItem('n_events', object.n_events)
        localStorage.setItem('n_files', object.n_files)
        localStorage.setItem('n_stories', object.n_stories)
        window.location.reload()
      }
    } catch (e) {
      if (typeof e.response === 'undefined') {
        setAlertMessage(t('drawerLogin.doLogin.networkError'))
        setAlertSeverity('error')
        setOpenAlert(true)
        setOnLogin(false)
      } else {
        setAlertMessage(e.response.data.message)
        setAlertSeverity('error')
        setOpenAlert(true)
        setOnLogin(false)
      }
    }
    setOnLogin(false)
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookDrawer[1](false)
      reset()
      if (openAlert) setOpenAlert(false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableBackdropTransition={true}
      disableSwipeToOpen={false}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookDrawer[1](false)
              reset()
              if (openAlert) setOpenAlert(false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={2}>
              <form onSubmit={handleSubmit(doLogin)}>
                <Stack spacing={2} sx={{width: '100%'}}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerLogin.formTitle')}
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerLogin.formEmail')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="email"
                        required
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onLogin}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerLogin.formPassword')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="password"
                        required
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onLogin}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onLogin}
                  >
                    {t('drawerLogin.formButtonLogin')}
                  </Button>
                </Stack>
              </form>
              <Collapse in={openAlert}>
                <Alert
                  severity={alertSeverity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit"/>
                    </IconButton>
                  }
                  sx={[{mb: 2}, appFont]}
                >
                  {alertMessage}
                </Alert>
              </Collapse>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}