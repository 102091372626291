import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import '../langs/config'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Stack,
  Typography,
  Backdrop,
  CircularProgress
} from '@mui/material'

import {
  backdropStyle,
  buttonStyle,
  loginBox,
  mascotappTitleWhite,
  textStyleWhite
} from '../assets/styles/styles'

import { DrawerLogin } from '../components/DrawerLogin'
import { DrawerRegister } from '../components/DrawerRegister'

//import logoImg from '../assets/images/logo.png'

function Login() {

  const { t } = useTranslation()

  const [drawerRegister, setDrawerRegister] = useState(false)
  const [drawerLogin, setDrawerLogin] = useState(false)

  const [backdrop, setBackdrop] = useState(false)

  const onBackButtonEvent = useCallback((e) => {
    e.preventDefault()
    if (drawerRegister) setDrawerRegister(false)
    if (drawerLogin) setDrawerLogin(false)
  }, [drawerRegister, drawerLogin])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => window.removeEventListener('popstate', onBackButtonEvent)
  }, [onBackButtonEvent])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="68vh"
    >
      <Backdrop sx={backdropStyle} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* BOX LONGON */}
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={loginBox}
        >

          {/*
            <CardMedia
              sx={{maxWidth: '150px', backgroundColor: 'white', borderRadius: '10px'}}
              src={logoImg}
              component="img"
            />
          */}


          <Typography variant="h3" sx={mascotappTitleWhite}>
            {t('login.mascotaweb')}
          </Typography>
          <Typography variant="h6" sx={textStyleWhite}>
            {t('login.logan')}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyle}
            onClick={() => setDrawerRegister(true)}
          >
            {t('login.buttonRegister')}
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyle}
            onClick={() => setDrawerLogin(true)}
          >
            {t('login.buttonLogin')}
          </Button>
          {/*
          <Typography variant="caption" sx={textStyleWhite}>
            {t('login.textTerms')}<Link href="#" color="inherit">{t('login.textTermsLink')}</Link>
            {t('login.textPolitics')}<Link href="#" color="inherit">{t('login.textPoliticsLink')}</Link>.
          </Typography>
          */}
        </Stack>
        <Typography variant="caption" sx={{ textAlign: 'center' }}>
          {`© ${new Date().getFullYear()} ` + t('login.copyright')}
          <br />
          {t('login.appVersion')}
        </Typography>
      </Stack>
      {/* DRAWER REGISTER */}
      <DrawerRegister hookDrawer={[drawerRegister, setDrawerRegister]} hookBackdrop={[backdrop, setBackdrop]} />
      {/* DRAWER LOGIN */}
      <DrawerLogin hookDrawer={[drawerLogin, setDrawerLogin]} hookBackdrop={[backdrop, setBackdrop]} />
    </Box>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login
