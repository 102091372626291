import {Controller, useForm} from 'react-hook-form'
import React, {useState, useEffect, useCallback} from 'react'
import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose,
  buttonStyleOnDrawer,
  drawerStyle,
  textInputStyle,
  textStyleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Grid, IconButton,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

export const DrawerAddPet = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm()
  const [onAddPet, setOnAddPet] = useState(false)

  const closeDrawer = useCallback(() => {
    props.hookDrawer[1](false)
    reset()
  }, [props, reset])

  const addPet = async (data) => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('name', data.name)
    formData.append('age', data.age)
    formData.append('description', data.description)
    formData.append('weight', '')
    formData.append('allergies', '')
    formData.append('race', '')
    formData.append('size', '')
    formData.append('chip', false)
    formData.append('sterilized', false)
    formData.append('photoId', '')
    formData.append('status', true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/pets`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        setOnAddPet(false)
      } else {
        props.handleSnackbar(message, 'success')
        props.listPets()
        setOnAddPet(false)
        closeDrawer()
      }
    } catch (e) {
      props.catchHandler(e)
      setOnAddPet(false)
    }
    setOnAddPet(false)
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      closeDrawer()
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => closeDrawer()}
      onOpen={() => {}}
      ModalProps={{keepMounted: true}}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => closeDrawer()}
          >
            <CloseIcon sx={buttonClose} />
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={1}>
              <form onSubmit={handleSubmit(addPet)}>
                <Stack spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    Agregar mascota
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddPet.form.name')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddPet}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="age"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddPet.form.age')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddPet}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddPet.form.description')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddPet}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onAddPet}
                  >
                    {t('drawerAddPet.buttonAdd')}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}