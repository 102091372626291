import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/es'

import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  MobileStepper,
  Stack,
  SwipeableDrawer,
  TextField, ThemeProvider,
  Typography
} from '@mui/material'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose,
  buttonStepper,
  buttonStyleOnDrawer,
  customTheme,
  drawerStyle,
  textInputStyle,
  textStyleBold
} from '../assets/styles/styles'

import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker'

moment.locale('es'); // set the locale to Spanish

export const DrawerAddEvent = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm()
  const [stepAddEvent, setStepAddEvent] = useState(0)
  const [onAddEvent, setOnAddEvent] = useState(false)

  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('warning')

  const addPetEvent = async (data) => {
    props.hookBackdrop[1](true)
    setOnAddEvent(true)
    if (
      data.title === '' ||
      data.description === '' ||
      data.date === ''
    ) {
      setAlertMessage(t('drawerAddEvent.addPetEvent.noData'))
      setAlertSeverity('warning')
      setOpenAlert(true)
      setOnAddEvent(false)
      return
    }
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('date', moment(data.date).toISOString())
    formData.append('status', true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/events`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        setOnAddEvent(false)
      } else {
        setOnAddEvent(false)
        props.getEvents(props.pet._id)
        props.hookDrawer[1](false)
        setStepAddEvent(0)
        reset()
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
      setOnAddEvent(false)
    }
    setOnAddEvent(false)
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (props.hookDrawer[0]) {
      reset({
        date: moment()
      })
    }
  }, [props.hookDrawer[0]])

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
      setStepAddEvent(0)
      reset()
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
              setStepAddEvent(0)
              reset()
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={1}>
              <form onSubmit={handleSubmit(addPetEvent)}>
                {/* 1er name */}
                <Stack sx={{display: stepAddEvent === 0 ? 'contents' : 'none', width: '100%'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    Datos del evento
                  </Typography>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddEvent.form.title')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddEvent}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddEvent.form.description')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddEvent}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                {/* 2nd type */}
                <Stack sx={{display: stepAddEvent === 1 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddEvent.form.eventDate')}
                  </Typography>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={true}
                    render={({field: {onChange, value}}) => (
                      <ThemeProvider theme={customTheme}>
                        <LocalizationProvider dateAdapter={AdapterMoment} locale='es'>
                          <StaticDateTimePicker
                            slotProps={{
                              actionBar: {actions: ['clear']}
                            }}
                            value={value}
                            onChange={onChange}
                            localeText={{
                              clearButtonLabel: 'Resetear',
                              dateTimePickerToolbarTitle: "Seleccionar fecha y hora"
                            }}
                          />
                        </LocalizationProvider>
                      </ThemeProvider>
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onAddEvent}
                  >
                    {t('drawerAddEvent.form.buttonAdd')}
                  </Button>
                </Stack>
                <ThemeProvider theme={customTheme}>
                  <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={stepAddEvent}
                    nextButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddEvent((prevActiveStep) => prevActiveStep + 1)}
                        disabled={stepAddEvent === 2 - 1}
                      >
                        {t('drawerAddEvent.form.steper.right')}
                        <KeyboardArrowRight/>
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddEvent((prevActiveStep) => prevActiveStep - 1)}
                        disabled={stepAddEvent === 0}
                      >
                        <KeyboardArrowLeft/>
                        {t('drawerAddEvent.form.steper.left')}
                      </Button>
                    }
                  />
                </ThemeProvider>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                    sx={[{mb: 2}, appFont]}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}