import {
  appFont,
  boxDrawerDetail,
  buttonClose,
  buttonStyleOnDrawerStyledDetail,
  dateTimePickerStyle,
  drawerStyle,
  textInputStyle,
  textSubtitleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Grid,
  SwipeableDrawer,
  TextField,
  Typography,
  IconButton
} from '@mui/material'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Controller, useForm} from 'react-hook-form'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'

export const DrawerDetailMed = (props) => {

  const {t} = useTranslation()

  const [onEdit, setOnEdit] = useState(true)
  const {control, handleSubmit, reset} = useForm()

  useEffect(() => {
    if (props.hookDrawer[0]) {
      reset({
        name: props.med.name,
        type: props.med.type,
        concentration: props.med.concentration + ' ' + props.med.unit,
        interval: props.med.interval === 'regular' ? t('drawerDetailMed.intervalRegular') : t('drawerDetailMed.intervalSpecific'),
        perHours: () => {
          switch (props.med.perHours) {
            case '1':
              return t('drawerDetailMed.hour')
            case '2':
              return t('drawerDetailMed.2hours')
            case '4':
              return t('drawerDetailMed.4hours')
            case '6':
              return t('drawerDetailMed.6hours')
            case '8':
              return t('drawerDetailMed.8hours')
            case '12':
              return t('drawerDetailMed.12hours')
            case '24':
              return t('drawerDetailMed.24hours')
            case 'Mo':
              return t('drawerDetailMed.monday')
            case 'Tu':
              return t('drawerDetailMed.tuesday')
            case 'We':
              return t('drawerDetailMed.wednesday')
            case 'Th':
              return t('drawerDetailMed.thursday')
            case 'Fr':
              return t('drawerDetailMed.friday')
            case 'Sa':
              return t('drawerDetailMed.saturday')
            case 'Su':
              return t('drawerDetailMed.sunday')
            case 'All':
              return t('drawerDetailMed.all')
            default:
              return null
          }
        },
        initDate: moment(props.med.initDate),
        finishDate: moment(props.med.finishDate),
        initTime: moment(props.med.initTime),
        displayName: props.med.displayName,
        notes: props.med.notes
      })
    }
  }, [props.hookDrawer, props.med.concentration, props.med.displayName, props.med.finishDate, props.med.initDate,
    props.med.initTime, props.med.interval, props.med.name, props.med.notes, props.med.perHours, props.med.type, props.med.unit])

  const deleteMed = async () => {
    props.hookBackdrop[1](true)
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_API}/api/meds/${props.med._id}`, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        props.getMeds(props.pet._id)
        props.hookDrawer[1](false)
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  const updateMed = async (data) => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('initDate', data.initDate)
    formData.append('finishDate', data.finishDate)
    formData.append('initTime', data.initTime)
    formData.append('displayName', data.displayName)
    formData.append('notes', data.notes)
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_API}/api/meds/${props.med._id}`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        setOnEdit(true)
        props.getMeds(props.pet._id)
        props.hookDrawer[1](false)
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawerDetail}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={[textSubtitleBold, {mb: 2}]}
          >
            {t('drawerDetailMed.med')}
          </Typography>
          <form onSubmit={handleSubmit(updateMed)}>
            <Controller
              name="name"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.name')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.type')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: true}}
                  disabled={!onEdit}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="concentration"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.concentration')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: true}}
                  disabled={!onEdit}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="interval"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.frequency')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: true}}
                  disabled={!onEdit}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="perHours"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.every')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: true}}
                  disabled={!onEdit}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="initDate"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    {...field}
                    readOnly={true}
                    selected={field.value}
                    label={t('drawerDetailMed.form.initDate')}
                    dateFormat={t('drawerDetailMed.form.initDateFormat')}
                    sx={[{
                      '.MuiInputBase-root': appFont,
                      '.MuiFormLabel-root': appFont,
                    },
                      dateTimePickerStyle
                    ]}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="finishDate"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    {...field}
                    readOnly={true}
                    selected={field.value}
                    label={t('drawerDetailMed.form.finishDate')}
                    dateFormat={t('drawerDetailMed.form.finishDateFormat')}
                    sx={[{
                      '.MuiInputBase-root': appFont,
                      '.MuiFormLabel-root': appFont,
                    },
                      dateTimePickerStyle
                    ]}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="initTime"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    {...field}
                    readOnly={true}
                    label={t('drawerDetailMed.form.startHour')}
                    selected={field.value}
                    sx={[{
                      '.MuiInputBase-root': appFont,
                      '.MuiFormLabel-root': appFont,
                    },
                      dateTimePickerStyle
                    ]}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="displayName"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.displayName')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailMed.form.notes')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            {onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
                fullWidth
                onClick={() => setOnEdit(false)}
              >
                {t('drawerDetailMed.form.buttons.edit')}
              </Button>
            }
            {!onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
                fullWidth
                type="submit"
              >
                {t('drawerDetailMed.form.buttons.update')}
              </Button>
            }
            <Button
              variant="outlined"
              disableElevation
              sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
              fullWidth
              onClick={() => deleteMed()}
            >
              {t('drawerDetailMed.form.buttons.delete')}
            </Button>
          </form>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )

}