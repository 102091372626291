import React, { useState, useEffect } from 'react'

import moment from 'moment'

import '../langs/config'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Container,
  Button,
  Grid,
  Card,
  List,
  ListItem,
  Divider,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  SwipeableDrawer,
  Box,
  Stack,
  ListItemButton,
  ThemeProvider,
  IconButton,
  Backdrop,
  CircularProgress
} from '@mui/material'

import {
  appFont, backdropStyle,
  boxDrawerDetail,
  buttonClose,
  buttonStyleOnDrawerStyledDetail,
  cardInfo,
  cardInfoOnDrawer,
  customTheme,
  drawerStyle,
  textStyleBoldCenter,
  textSubtitleBold,
  textTitle
} from '../assets/styles/styles'

import axios from 'axios'

import CloseIcon from '@mui/icons-material/Close'
import { DialogProfilePhoto } from '../components/DialogProfilePhoto'

function Account() {

  const { t } = useTranslation()

  const [token] = useState(localStorage.getItem('token'))
  const [userID] = useState(localStorage.getItem('userID'))

  const [userName, setUserName] = useState('')
  const [userLasName, setUserLasName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userTier, setUserTier] = useState('')
  const [userTierStart, setUserTierStart] = useState('')
  const [userTierEnds, setUserTierEnds] = useState('')
  const [userPhotoId, setUserPhotoId] = useState('')

  const [subscriber, setSubscriber] = useState(null)
  const [mediaToken, setMediaToken] = useState('')

  const [resetAll, setResetAll] = useState(false)

  // Snackbat hooks
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const [drawerDetailPerfil, setDrawerDetailPerfil] = useState(false)
  const [drawerDetailAccount, setDrawerDetailAccount] = useState(false)
  const [drawerDetailHelp, setDrawerDetailHelp] = useState(false)
  const [drawerDetailAbout, setDrawerDetailAbout] = useState(false)

  const [openDialogLogOut, setOpenDialogLogOut] = useState(false)
  const [openDialogDeleteAccount, setOpenDialogDeleteAccount] = useState(false)

  const [openAddPhoto, setAddPhoto] = useState(false)

  const [backdrop, setBackdrop] = useState(false)

  const axiosConfig = {
    headers: { 'content-type': 'multipart/form-data', 'x-access-token': token },
    timeout: process.env.REACT_APP_AXIOS_TIMEOUT
  }

  const catchHandler = (e) => {
    if (typeof e.response === 'undefined') {
      handleSnackbar(t('account.networkError'), 'error')
    } else {
      handleSnackbar(e.response.data.message, 'error')
    }
  }

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setOpenSnackbar(true)
  }

  const getInfo = async () => {
    //if (!loading) setLoading(true)
    setSubscriber({})
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/subscribers/${userID}`, axiosConfig)
      let error = response.data.error
      let message = response.data.message

      if (error) {
        //if (message === 'Without authorization token') signout()
        handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        //console.log(response.data.object)
        setSubscriber(response.data.object)
        setMediaToken(response.data.mediaToken)

        setUserName(response.data.object.firstName)
        setUserLasName(response.data.object.lastName)
        setUserEmail(response.data.object.email)
        setUserPhone(response.data.object.phone)
        setUserTier(response.data.object.tier)
        setUserTierStart(response.data.object.tierStart)
        setUserTierEnds(response.data.object.tierEnds)
        setUserPhotoId(response.data.object.photoId)

      }
    } catch (e) {
      catchHandler(e)
    }
    //setLoading(false)
  }

  useEffect(() => {
    if (subscriber === null) getInfo()
  })

  useEffect(() => {
    if (resetAll) {
      getInfo()
      setResetAll(false)
    }
  }, [resetAll])

  const logout = () => {
    setBackdrop(true)
    localStorage.clear()
    window.location.reload()
    setBackdrop(false)
  }

  const deleteAccount = async () => {
    setBackdrop(true)
    const formData = new FormData()
    formData.append('subscriber', userID)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/subscribers/deleteAccount`, formData, axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        handleSnackbar(message, 'error')
      } else {
        handleSnackbar(message, 'success')
        logout()
      }
    } catch (e) {
      catchHandler(e)
    }
    setBackdrop(false)
  }

  useEffect(() => {
    function onBackButtonEvent(e) {
      e.preventDefault();
      if (drawerDetailPerfil) setDrawerDetailPerfil(false)
      if (drawerDetailAccount) setDrawerDetailAccount(false)
      if (drawerDetailHelp) setDrawerDetailHelp(false)
      if (drawerDetailAbout) setDrawerDetailAbout(false)
      if (openAddPhoto) setAddPhoto(false)
    }
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [drawerDetailPerfil, drawerDetailAccount, drawerDetailHelp, drawerDetailAbout, openAddPhoto])

  return (
    <Container maxWidth="sm">
      <Backdrop sx={backdropStyle} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h4" gutterBottom sx={textTitle}>
        {t('account.title')}
      </Typography>
      <Grid
        container
        direction="column"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 2, mt: 2 }}
        >
          <Avatar
            alt={`${userName.substring(0, 1)}${userLasName.substring(0, 1)}`}
            src={userPhotoId && userPhotoId}
            onClick={() => {
              setAddPhoto(true)
            }}
            sx={{ width: 92, height: 92, mb: 2 }}
          />
          <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
            {`${userName} ${userLasName}`}
          </Typography>
        </Grid>
        <Card variant="outlined" elevation={0} sx={cardInfo}>
          <List sx={appFont}>
            <ListItemButton onClick={() => setDrawerDetailPerfil(true)}>
              {t('account.perfil')}
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setDrawerDetailAccount(true)}>
              {t('account.account')}
            </ListItemButton>
          </List>
        </Card>
        <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
          {t('account.info')}
        </Typography>
        <Card variant="outlined" elevation={0} sx={cardInfo}>
          <List sx={appFont}>
            <ListItemButton onClick={() => setDrawerDetailHelp(true)}>
              {t('account.help')}
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setDrawerDetailAbout(true)}>
              {t('account.about')}
            </ListItemButton>
          </List>
        </Card>
        <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
          {t('account.options')}
        </Typography>
        <List>
          <ListItem>
            <Button
              variant="outlined"
              disableElevation
              sx={buttonStyleOnDrawerStyledDetail}
              fullWidth
              onClick={() => setOpenDialogDeleteAccount(true)}
            >
              {t('account.deleteAccount')}
            </Button>
          </ListItem>
          <ListItem>
            <Button
              variant="outlined"
              disableElevation
              sx={buttonStyleOnDrawerStyledDetail}
              fullWidth
              onClick={() => setOpenDialogLogOut(true)}
            >
              {t('account.logOut')}
            </Button>
          </ListItem>
        </List>
      </Grid>
      {/* DRAWER PERFIL DETAIL */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerDetailPerfil}
        onOpen={() => true}
        onClose={() => setDrawerDetailPerfil(false)}
        ModalProps={{
          keepMounted: true,
        }}
        disableSwipeToOpen={true}
        sx={drawerStyle}
      >
        <Box sx={boxDrawerDetail}>
          <Grid container justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setDrawerDetailPerfil(false)
              }}
            >
              <CloseIcon sx={buttonClose} />
            </IconButton>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            direction="column"
          >
            <Grid item>
              <Stack spacing={1}>
                <Stack spacing={2}>
                  <Typography variant="h6" sx={textStyleBoldCenter}>
                    {t('account.perfil')}
                  </Typography>
                  <Card variant="outlined" elevation={0} sx={cardInfoOnDrawer}>
                    <List sx={[{ width: '100%' }, appFont]}>
                      <ListItem>
                        {`${userName} ${userLasName}`}
                      </ListItem>
                      <Divider />
                      <ListItem>
                        {userEmail}
                      </ListItem>
                      {userPhone &&
                        <>
                          <Divider />
                          <ListItem>
                            {userPhone}
                          </ListItem>
                        </>
                      }
                    </List>
                  </Card>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      {/* DRAWER ACCOUNT DETAIL */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerDetailAccount}
        onOpen={() => true}
        onClose={() => setDrawerDetailAccount(false)}
        ModalProps={{
          keepMounted: true,
        }}
        disableSwipeToOpen={true}
        sx={drawerStyle}
      >
        <Box sx={boxDrawerDetail}>
          <Grid container justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setDrawerDetailAccount(false)
              }}
            >
              <CloseIcon sx={buttonClose} />
            </IconButton>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            direction="column"
          >
            <Grid item>
              <Stack spacing={1}>
                <Stack spacing={2}>
                  <Typography variant="h6" sx={textStyleBoldCenter}>
                    {t('account.account')}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
                    {t('account.subscription')}
                  </Typography>
                  <Card variant="outlined" elevation={0} sx={cardInfoOnDrawer}>
                    <List sx={[{ width: '100%' }, appFont]}>
                      <ListItem>
                        {userTier}
                      </ListItem>
                      <Divider />
                      <ListItem>
                        {t('account.subsStart')}{moment(userTierStart).format('DD/MM/YYYY h:mm:s a')}
                      </ListItem>
                      <Divider />
                      <ListItem>
                        {t('account.subsEnds')}{userTierEnds ? t('account.subVital') : moment(userTierEnds).format('DD/MM/YYYY h:mm:s a')}
                      </ListItem>
                    </List>
                  </Card>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      {/* DRAWER HELP DETAIL */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerDetailHelp}
        onOpen={() => true}
        onClose={() => setDrawerDetailHelp(false)}
        ModalProps={{
          keepMounted: true,
        }}
        disableSwipeToOpen={true}
        sx={drawerStyle}
      >
        <Box sx={boxDrawerDetail}>
          <Grid container justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setDrawerDetailHelp(false)
              }}
            >
              <CloseIcon sx={buttonClose} />
            </IconButton>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            direction="column"
          >
            <Grid item>
              <Stack spacing={1}>
                <Stack spacing={2}>
                  <Typography variant="h6" sx={textStyleBoldCenter}>
                    {t('account.help')}
                  </Typography>
                  <List>
                    <ListItem sx={[{ textAlign: 'justify', textJustify: 'inter-word' }, appFont]}>
                      {t('account.helpText')}
                    </ListItem>
                  </List>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      {/* DRAWER ABOUT DETAIL */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerDetailAbout}
        onOpen={() => true}
        onClose={() => setDrawerDetailAbout(false)}
        ModalProps={{
          keepMounted: true,
        }}
        disableSwipeToOpen={true}
        sx={drawerStyle}
      >
        <Box sx={boxDrawerDetail}>
          <Grid container justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setDrawerDetailAbout(false)
              }}
            >
              <CloseIcon sx={buttonClose} />
            </IconButton>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            direction="column"
          >
            <Grid item>
              <Stack spacing={1}>
                <Stack spacing={2}>
                  <Typography variant="h6" sx={textStyleBoldCenter}>
                    {t('account.about')}
                  </Typography>
                  <List>
                    <ListItem sx={[{ textAlign: 'justify', textJustify: 'inter-word' }, appFont]}>
                      {t('account.aboutText')}
                    </ListItem>
                  </List>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>

      {/* DIALOG PHOTO */}
      <DialogProfilePhoto
        hookDialogFileScan={[openAddPhoto, setAddPhoto]}
        hookBackdrop={[backdrop, setBackdrop]}
        userID={userID}
        userPhotoId={userPhotoId}
        axiosConfig={axiosConfig}
        handleSnackbar={handleSnackbar}
        catchHandler={catchHandler}
        mediaToken={mediaToken}
        resetAll={setResetAll}
      />

      <ThemeProvider theme={customTheme}>
        <Dialog
          open={openDialogDeleteAccount}
          onClose={() => setOpenDialogDeleteAccount(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={appFont}>
            {t('account.deleteAccount')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={appFont}>
              {t('account.deleteAccountConfirm')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={appFont} onClick={() => setOpenDialogDeleteAccount(false)}>{t('account.deleteAccountConfirmButtonCancel')}</Button>
            <Button sx={appFont} onClick={() => deleteAccount()}>{t('account.deleteAccountConfirmButtonDelete')}</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogLogOut}
          onClose={() => setOpenDialogLogOut(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={appFont}>
            {t('account.logOut')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={appFont}>
              {t('account.logOutConfirm')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={appFont} onClick={() => setOpenDialogLogOut(false)}>{t('account.logOutConfirmCancel')}</Button>
            <Button sx={appFont} onClick={() => logout()}>{t('account.logOutConfirmConfirm')}</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      {/* SNACKBAR */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity ? snackbarSeverity : 'warning'}
          sx={[{ mb: 2 }, appFont]}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Account