import {
  appFont,
  boxDrawerDetail,
  buttonClose,
  buttonStyleOnDrawerStyledDetail,
  customTheme,
  drawerStyle,
  textInputStyle,
  textSubtitleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Grid,
  SwipeableDrawer,
  TextField,
  Typography, ThemeProvider, IconButton
} from '@mui/material'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import axios from 'axios'

import {Controller, useForm} from 'react-hook-form'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker'

export const DrawerDetailEvent = (props) => {

  const {t} = useTranslation()

  const [onEdit, setOnEdit] = useState(true)
  const {control, handleSubmit, reset} = useForm()

  useEffect(() => {
    if (props.hookDrawer[0]) {
      reset({
        title: props.event.title,
        description: props.event.description,
        date: moment(props.event.date)
      })
    }
  }, [props.hookDrawer, props.event.title, props.event.description, props.event.date])

  const deleteEvent = async () => {
    props.hookBackdrop[1](true)
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_API}/api/events/${props.event._id}`, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        props.getEvents(props.pet._id)
        props.hookDrawer[1](false)
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  const updateEvent = async (data) => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('date', data.date)
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_API}/api/events/${props.event._id}`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        setOnEdit(true)
        props.getEvents(props.pet._id)
        props.hookDrawer[1](false)
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawerDetail}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={[textSubtitleBold, {mb: 2}]}
          >
            {t('drawerDetailEvent.title')}
          </Typography>
          <form onSubmit={handleSubmit(updateEvent)}>
            <Controller
              name="title"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailEvent.form.title')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailEvent.form.description')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="date"
              control={control}
              defaultValue={true}
              render={({field: {onChange, value}}) => (
                <ThemeProvider theme={customTheme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDateTimePicker
                      slotProps={{
                        actionBar: {actions: ['clear']}
                      }}
                      readOnly={onEdit}
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => <TextField {...params} />}
                      localeText={{
                        clearButtonLabel: 'Resetear',
                        dateTimePickerToolbarTitle: "Fecha y hora del evento"
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              )}
            />
            {onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
                fullWidth
                onClick={() => setOnEdit(false)}
              >
                {t('drawerDetailEvent.form.buttons.edit')}
              </Button>
            }
            {!onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
                fullWidth
                type="submit"
              >
                {t('drawerDetailEvent.form.buttons.update')}
              </Button>
            }

            <Button
              variant="outlined"
              disableElevation
              sx={[buttonStyleOnDrawerStyledDetail, {mt: 2}]}
              fullWidth
              onClick={() => deleteEvent()}
            >
              {t('drawerDetailEvent.form.buttons.delete')}
            </Button>
          </form>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}