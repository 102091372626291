import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {appFont} from "../assets/styles/styles";

export const DialogConfirm = (props) => {

  return (
    <Dialog
      open={props.hookDialog[0]}
      onClose={() => props.hookDialog[1](false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '.MuiTypography-root': appFont,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={appFont} onClick={() => props.hookDialog[1](false)}>{props.no}</Button>
        <Button sx={appFont}  onClick={props.toFunction}>{props.yes}</Button>
      </DialogActions>
    </Dialog>
  )

}