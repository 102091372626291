import React, { useEffect, useRef, useState } from 'react'

import '../langs/config'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonGroup
} from '@mui/material'

import {
  buttonStyleOnDrawerOutlined,
  buttonStyleOnDrawerOutlinedGroup,
} from '../assets/styles/styles'

import { DialogConfirm } from './DialogConfirm'
import axios from 'axios'

export const FileInputFilePdf = (props) => {

  const { t } = useTranslation()

  const inputRef = useRef()
  const [file, setFile] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const [statusPdf, setStatusPdf] = useState(false)

  const handleOnChange = (event) => {
    if (props.hookDrawer[0]) props.hookDrawer[1](false)
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files)
      setOpenDialog(true)
    }
  }

  const uploadFile = async () => {
    setOpenDialog(false)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet)
    formData.append('fileName', props.file._id)
    formData.append('uploadFile', file[0])
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files/uploadFilePdf`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        props.handleSnackbar(message, 'success')
        setStatusPdf(false)
        setOpenDialog(false)
        if (props.hookDrawer[0]) props.hookDrawer[1](true)
        props.reloadAll(true)
        //props.drawerDetailFile(false)
      }
    } catch (e) {
      props.catchHandler(e)
    }
  }
  const onChooseImg = () => {
    inputRef.current.click()
  }
  const deletePdf = async () => {
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet)
    formData.append('file', props.file._id)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files/deleteFilePdf`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        //fetch(`${process.env.REACT_APP_SERVER_API}/media/pets/${props.userID}/files/${props.pet}/${props.file._id}-${props.file.pdfId}.pdf?p=${props.mediaFileToken}`)
        //  .then(response => setStatusPdf(response.status))
        props.file.pdfId = ""
        props.handleSnackbar(message, 'success')
        props.reloadAll(true)
        //props.drawerDetailFile(false)
      }
    } catch (e) {
      props.catchHandler(e)
    }
  }
  /*
  useEffect(() => {
    if (props.hookDrawer[0]) {
      fetch(`${process.env.REACT_APP_SERVER_API}/media/pets/${props.userID}/files/${props.pet}/${props.file._id}-${props.file.pdfId}.pdf?p=${props.mediaFileToken}`)
        .then(response => setStatusPdf(response.status))
    }
  }, [props.hookDrawer, props.userID, props.pet, props.file])
  */

  return (
    <>
      <input
        type="file"
        accept=".pdf"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
      {props.file.pdfId ?
        <ButtonGroup
          aria-label="outlined button group"
          disableElevation
        >
          <Button
            variant="outlined"
            sx={buttonStyleOnDrawerOutlinedGroup}
            href={props.file.pdfId}
            target='_blank'
          >
            {t('fileInputFilePdf.buttons.look')}
          </Button>
          <Button
            variant="outlined"
            sx={buttonStyleOnDrawerOutlinedGroup}
            onClick={deletePdf}
          >
            {t('fileInputFilePdf.buttons.delete')}
          </Button>
        </ButtonGroup>
        :
        <Button
          variant="outlined"
          disableElevation
          sx={buttonStyleOnDrawerOutlined}
          fullWidth
          onClick={onChooseImg}
        >
          {t('fileInputFilePdf.buttons.upload')}
        </Button>
      }
      <DialogConfirm
        hookDialog={[openDialog, setOpenDialog]}
        title={t('fileInputFilePdf.dialog.title')}
        message={t('fileInputFilePdf.dialog.message')}
        toFunction={uploadFile}
        no={t('fileInputFilePdf.dialog.buttonCancel')}
        yes={t('fileInputFilePdf.dialog.buttonUpload')}
      />
    </>
  )
}