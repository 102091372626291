import React from 'react'
import {Alert, Snackbar} from '@mui/material'
import {appFont} from '../assets/styles/styles'

export const SnackbarComp = (props) => {

  return (
    <Snackbar
      open={props.hookSnackBar[0]}
      autoHideDuration={6000}
      onClose={() => props.hookSnackBar[1](false)}
    >
      <Alert
        onClose={() => props.hookSnackBar[1](false)}
        severity={props.snackSeverity ? props.snackSeverity : 'warning'}
        sx={[{width: '100%'}, appFont]}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )

}