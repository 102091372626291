import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import axios from 'axios'
import moment from 'moment'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose,
  buttonStepper,
  buttonStyleOnDrawer,
  customTheme,
  drawerStyle,
  textInputStyle,
  textStyleBold
} from '../assets/styles/styles'

import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  MobileStepper,
  Stack,
  SwipeableDrawer,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DateCalendar} from '@mui/x-date-pickers'

export const DrawerAddFile = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm({
    defaultValues: {
      fileDate: moment()
    }
  })

  const [stepAddFiles, setStepAddFiles] = useState(0)
  const [onAddFile, setOnAddFile] = useState(false)

  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('warning')

  const addPetFile = async (data) => {
    props.hookBackdrop[1](true)
    setOnAddFile(true)
    if (
      data.fileTitle === '' ||
      data.fileDescription === '' ||
      data.fileDate === ''
    ) {
      setAlertMessage(t('drawerAddFile.addPetFile.noData'))
      setAlertSeverity('warning')
      setOpenAlert(true)
      setOnAddFile(false)
      return
    }
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('title', data.fileTitle)
    formData.append('description', data.fileDescription)
    formData.append('date', moment(data.fileDate).toISOString())
    formData.append('photoId', '')
    formData.append('pdfId', '')
    formData.append('status', true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        setOnAddFile(false)
      } else {
        props.hookFile[1](response.data.file)
        setOnAddFile(false)
        props.hookDrawer[1](false)
        //props.hookDialogFileScan[1](true)
        setStepAddFiles(0)
        reset()
        props.getFiles(props.pet._id)
        //props.hookDrawerDetailFile[1](true)
      }
    } catch (e) {
      props.catchHandler(e)
      setOnAddFile(false)
    }
    setOnAddFile(false)
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
      setStepAddFiles(0)
      reset()
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
              setStepAddFiles(0)
              reset()
            }}
          >
            <CloseIcon sx={buttonClose} />
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={1}>
              <form onSubmit={handleSubmit(addPetFile)}>
                {/* 1er name */}
                <Stack sx={{display: stepAddFiles === 0 ? 'contents' : 'none', width: '100%'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddFile.form.fileData')}
                  </Typography>
                  <Controller
                    name="fileTitle"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddFile.form.title')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddFile}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="fileDescription"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddFile.form.desc')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddFile}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                {/* 2nd type */}
                <Stack sx={{display: stepAddFiles === 1 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddFile.form.fileDate')}
                  </Typography>
                  <Controller
                    name="fileDate"
                    control={control}
                    defaultValue={true}
                    render={({field: {onChange, value}}) => (
                      <ThemeProvider theme={customTheme}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateCalendar
                            inputformat={t('drawerAddFile.form.dateFormat')}
                            value={value}
                            onChange={onChange}
                          />
                        </LocalizationProvider>
                      </ThemeProvider>
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onAddFile}
                  >
                    {t('drawerAddFile.form.buttonAdd')}
                  </Button>
                </Stack>
                <ThemeProvider theme={customTheme}>
                  <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={stepAddFiles}
                    nextButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddFiles((prevActiveStep) => prevActiveStep + 1)}
                        disabled={stepAddFiles === 2 - 1}
                      >
                        {t('drawerAddMed.steper.right')}
                        <KeyboardArrowRight/>
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddFiles((prevActiveStep) => prevActiveStep - 1)}
                        disabled={stepAddFiles === 0}
                      >
                        <KeyboardArrowLeft/>
                        {t('drawerAddMed.steper.left')}
                      </Button>
                    }
                  />
                </ThemeProvider>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                    sx={[{mb: 2}, appFont]}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}