import React, {useEffect, useState, useCallback} from 'react'
import moment from 'moment'
import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar,
  Typography,
  Skeleton
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import MedicationIcon from '@mui/icons-material/Medication'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

import {
  appFont,
  buttonStyleOnDrawer,
  buttonStyleOnDrawerStyled,
  cardInfo,
  mediaPic,
  textSubtitleBold
} from '../assets/styles/styles'

import medsPhoto from '../assets/images/pet.detail.meds.jpg'
import filesPhoto from '../assets/images/pet.detail.files.jpg'
import eventsPhoto from '../assets/images/pet.detail.visits.jpg'

import {DrawerAddMed} from './DrawerAddMed'
import {DrawerAddFile} from './DrawerAddFile'
import {DrawerDetailMed} from './DrawerDetailMed'
import {DrawerDetailFile} from './DrawerDetailFile'
import {DrawerDetailEvent} from './DrawerDetailEvent'
import {DialogFileScan} from './DialogFileScan'
import {DialogConfirm} from './DialogConfirm'
import {DrawerDetailPetPerfil} from './DrawerDetailPetPerfil'
import {DrawerAddEvent} from './DrawerAddEvent'

import MoreVertIcon from '@mui/icons-material/MoreVert'

export const DialogDetailsPet = (props) => {

  const {t} = useTranslation()

  const [n_meds] = useState(localStorage.getItem('n_meds'))
  const [n_events] = useState(localStorage.getItem('n_events'))
  const [n_files] = useState(localStorage.getItem('n_files'))

  const [filesPet, setFilesPet] = useState({object: null, count: 0})
  const [medsList, setMedsList] = useState({object: null, count: 0})
  const [eventsList, setEventsList] = useState({object: null, count: 0})

  const [mediaFileToken, setMediaFileToken] = useState(null)

  const [loadingMeds, setLoadingMeds] = useState(true)
  const [loadingEvents, setLoadingEvents] = useState(true)
  const [loadingFiles, setLoadingFiles] = useState(true)

  const [drawerAddMed, setDrawerAddMed] = useState(false)
  const [drawerAddFile, setDrawerAddFile] = useState(false)
  const [drawerDetailMed, setDrawerDetailMed] = useState(false)
  const [drawerDetailFile, setDrawerDetailFile] = useState(false)
  const [drawerPetPerfil, setDrawerPetPerfil] = useState(false)
  const [drawerEditMed, setDrawerEditMed] = useState(false)
  const [drawerAddEvent, setDrawerAddEvent] = useState(false)
  const [drawerDetailEvent, setDrawerDetailEvent] = useState(false)

  const [openAddFile, setOpenAddFile] = useState(false)

  const [reloadAll, setReloadAll] = useState(false)

  const [file, setFile] = useState('')
  const [med, setMed] = useState('')
  const [event, setEvent] = useState('')

  const [openDialogDeletePet, setOpenDialogDeletePet] = useState(false)

  const onBackButtonEvent = useCallback((e) => {
    e.preventDefault()
    if (drawerAddMed) setDrawerAddMed(false)
    if (drawerAddFile) setDrawerAddFile(false)
    if (drawerDetailMed) setDrawerDetailMed(false)
    if (drawerDetailFile) setDrawerDetailFile(false)
    if (drawerPetPerfil) setDrawerPetPerfil(false)
    if (drawerEditMed) setDrawerEditMed(false)
    if (drawerAddEvent) setDrawerAddEvent(false)
    if (drawerDetailEvent) setDrawerDetailEvent(false)
    if (openAddFile) setOpenAddFile(false)
  }, [drawerAddMed, drawerAddFile, drawerDetailMed, drawerDetailFile, drawerPetPerfil
    , drawerEditMed, drawerAddEvent, drawerDetailEvent, openAddFile])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => window.removeEventListener('popstate', onBackButtonEvent)
  }, [onBackButtonEvent])

  const getMeds = async (pet) => {
    if (!loadingMeds) setLoadingMeds(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/meds/listByPet/${pet}`, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        //if (message === 'Without authorization token') signout()
        props.handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        setMedsList(response.data.object)
      }
    } catch (e) {
      props.catchHandler(e)
    }
    setLoadingMeds(false)
  }

  const getEvents = async (pet) => {
    if (!loadingEvents) setLoadingEvents(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/events/listByPet/${pet}`, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        //if (message === 'Without authorization token') signout()
        props.handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        setEventsList(response.data.object)
      }
    } catch (e) {
      props.catchHandler(e)
    }
    setLoadingEvents(false)
  }

  const getFiles = async (pet) => {
    if (!loadingFiles) setLoadingFiles(true)
    // Get Files
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/files/listByPet/${pet}`, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        //if (message === 'Without authorization token') signout()
        props.handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        setFilesPet(response.data.object)
        setMediaFileToken(response.data.mediaToken)
      }
    } catch (e) {
      props.catchHandler(e)
    }
    setLoadingFiles(false)
  }

  useEffect(() => {
    if (props.hookDialog[0] || reloadAll) {
      getMeds(props.pet._id).then(r => {
      })
      getEvents(props.pet._id).then(r => {
      })
      getFiles(props.pet._id).then(r => {
      })
      setReloadAll(false)
    }
  }, [props.hookDialog[0], reloadAll])

  const deletePet = async () => {
    props.hookBackdrop[1](true)
    setOpenDialogDeletePet(false)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/pets/delete`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        props.listPets()
        cleanDetailPet()
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  const cleanDetailPet = () => {
    props.hookDialog[1](false)
    setMedsList(null)
    setFilesPet(null)
  }

  return (
    <Dialog
      fullScreen
      open={props.hookDialog[0]}
      onClose={() => cleanDetailPet()}
      PaperProps={{
        style: {
          backgroundColor: '#f1f0f4'
        },
      }}
    >
      <AppBar sx={{
        position: 'relative',
        mb: 2,
        backgroundColor: '#2d6a4f'
      }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => cleanDetailPet()}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={[{ml: 2, flex: 1}, appFont]} variant="h6" component="div">
            {props.pet.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">

        {/* HEALTH DATA */}
        <>
          <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
            {t('dialogDetailsPet.medicHistory')}
          </Typography>
          <Card key={'cardperfil'} variant="outlined" elevation={0} sx={cardInfo}>
            <List sx={{width: '100%'}}>
              <ListItem>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Typography variant="body2" gutterBottom sx={appFont}>
                    {t('dialogDetailsPet.petName')}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={appFont}>
                    {props.pet.name}
                  </Typography>
                </Grid>
              </ListItem>
              {props.pet.age &&
                <>
                  <Divider/>
                  <ListItem>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Typography variant="body2" gutterBottom sx={appFont}>
                        {t('dialogDetailsPet.petAge')}
                      </Typography>
                      <Typography variant="body1" gutterBottom sx={appFont}>
                        {props.pet.age}
                      </Typography>
                    </Grid>
                  </ListItem>
                </>
              }
              {props.pet.weight &&
                <>
                  <Divider/>
                  <ListItem>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Typography variant="body2" gutterBottom sx={appFont}>
                        {t('dialogDetailsPet.petWeight')}
                      </Typography>
                      <Typography variant="body1" gutterBottom sx={appFont}>
                        {props.pet.weight}
                      </Typography>
                    </Grid>
                  </ListItem>
                </>
              }
            </List>
          </Card>
          <Button
            variant="outlined"
            disableElevation
            sx={buttonStyleOnDrawerStyled}
            fullWidth
            onClick={() => {
              props.hookChildOpen(true)
              setDrawerPetPerfil(true)
            }}
          >
            {t('dialogDetailsPet.lookHistoryButton')}
          </Button>
        </>
        {/* MEDS AREA */}
        <>
          <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
            {t('dialogDetailsPet.petMeds')}
          </Typography>
          {/* MEDS ADD CARD */
            medsList && medsList.length === 0 &&
            <Card key={'cardaddmed'} elevation={0} sx={cardInfo} variant="outlined">
              <CardMedia sx={mediaPic} image={medsPhoto}/>
              <CardContent>
                <Stack spacing={2}>
                  <Typography gutterBottom variant="h5" component="div" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.medsCard.title1')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.medsCard.title2')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.medsCard.title3')}
                  </Typography>
                  <Button
                    variant="outlined"
                    disableElevation
                    sx={buttonStyleOnDrawerStyled}
                    fullWidth
                    onClick={() => {
                      props.hookChildOpen(true)
                      setDrawerAddMed(true)
                    }}
                  >
                    {t('dialogDetailsPet.medsCard.buttonAdd')}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          }

          {
            loadingMeds &&
            Array.from({length: 2}, (_, i) =>
              <Card key={`cardmedsskeletons-${i}`} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={
                    <Skeleton variant="rounded" width={40} height={40}/>
                  }
                  action={
                    <Skeleton variant="circular" width={40} height={40}/>
                  }
                  title={
                    <Skeleton variant="text" width={160} sx={{fontSize: '1rem'}}/>
                  }
                  subheader={
                    <Skeleton variant="text" width={240} sx={{fontSize: '1rem'}}/>
                  }
                />
              </Card>
            )
          }

          {/* MEDS LIST */
            medsList && medsList.length > 0 && medsList.map((object) => (
              <Card key={object._id} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={<Avatar variant="rounded" sx={{bgcolor: '#2d6a4f', width: 56, height: 56}}>
                    <MedicationIcon sx={{width: 42, height: 42}}/>
                  </Avatar>}
                  action={
                    <>
                      <IconButton
                        aria-label="settings"
                        onClick={() => {
                          props.hookChildOpen(true)
                          setMed(object)
                          setDrawerDetailMed(true)
                        }}
                      >
                        <MoreVertIcon/>
                      </IconButton>
                    </>
                  }
                  title={
                    <Typography variant="body1" gutterBottom sx={appFont}>
                      {object.name}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="body2" gutterBottom sx={appFont}>
                      {
                        object.type + ', ' + (() => {
                          switch (object.interval) {
                            case 'regular':
                              return t('dialogDetailsPet.medList.interval.regular')
                            case 'specific':
                              return t('dialogDetailsPet.medList.interval.specific')
                            default:
                              return null
                          }
                        })() + ' ' + (() => {
                          switch (object.perHours) {
                            case '1':
                              return t('dialogDetailsPet.medList.perHours.hour')
                            case '2':
                              return t('dialogDetailsPet.medList.perHours.2hours')
                            case '4':
                              return t('dialogDetailsPet.medList.perHours.4hours')
                            case '6':
                              return t('dialogDetailsPet.medList.perHours.6hours')
                            case '8':
                              return t('dialogDetailsPet.medList.perHours.8hours')
                            case '12':
                              return t('dialogDetailsPet.medList.perHours.12hours')
                            case '24':
                              return t('dialogDetailsPet.medList.perHours.24hours')
                            case 'Mo':
                              return t('dialogDetailsPet.medList.perHours.monday') + moment(object.initTime).format('h:mm a')
                            case 'Tu':
                              return t('dialogDetailsPet.medList.perHours.tuesday') + moment(object.initTime).format('h:mm a')
                            case 'We':
                              return t('dialogDetailsPet.medList.perHours.wednesday') + moment(object.initTime).format('h:mm a')
                            case 'Th':
                              return t('dialogDetailsPet.medList.perHours.thursday') + moment(object.initTime).format('h:mm a')
                            case 'Fr':
                              return t('dialogDetailsPet.medList.perHours.friday') + moment(object.initTime).format('h:mm a')
                            case 'Sa':
                              return t('dialogDetailsPet.medList.perHours.saturday') + moment(object.initTime).format('h:mm a')
                            case 'Su':
                              return t('dialogDetailsPet.medList.perHours.sunday') + moment(object.initTime).format('h:mm a')
                            case 'All':
                              return t('dialogDetailsPet.medList.perHours.all') + moment(object.initTime).format('h:mm a')
                            default:
                              return null
                          }
                        })()
                      }
                    </Typography>
                  }
                />
              </Card>
            ))
          }

          {/* MEDS ADD BUTTON */
            medsList && medsList.length > 0 &&
            <Button
              variant="outlined"
              disableElevation
              sx={buttonStyleOnDrawerStyled}
              fullWidth
              onClick={() => {
                if (medsList.length === n_meds*1) {
                  props.handleSnackbar("Limite de medicamentos alcanzado", 'warning')
                } else {
                  props.hookChildOpen(true)
                  setDrawerAddMed(true)
                }
              }}
            >
              {t('dialogDetailsPet.medAddButton')}
            </Button>
          }
        </>
        {/* EVENTS AREA */}
        <>
          <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
            {t('dialogDetailsPet.eventsTitle')}
          </Typography>
          {/* EVENTS ADD CARD */
            eventsList && eventsList.length === 0 &&
            <Card key={`cardaddevent`} elevation={0} sx={cardInfo} variant="outlined">
              <CardMedia sx={mediaPic} image={eventsPhoto}/>
              <CardContent>
                <Stack spacing={2}>
                  <Typography gutterBottom variant="h5" component="div" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.eventsCard.title1')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.eventsCard.title2')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.eventsCard.title3')}
                  </Typography>
                  <Button
                    variant="outlined"
                    disableElevation
                    sx={buttonStyleOnDrawerStyled}
                    fullWidth
                    onClick={() => {
                      props.hookChildOpen(true)
                      setDrawerAddEvent(true)
                    }}
                  >
                    {t('dialogDetailsPet.eventsCard.buttonAddEvent')}
                  </Button>
                </Stack>
              </CardContent>
            </Card>}

          {
            loadingEvents &&
            Array.from({length: 2}, (_, i) =>
              <Card key={`cardeventsskeletons-${i}`} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={
                    <Skeleton variant="rounded" width={40} height={40}/>
                  }
                  action={
                    <Skeleton variant="circular" width={40} height={40}/>
                  }
                  title={
                    <Skeleton variant="text" width={160} sx={{fontSize: '1rem'}}/>
                  }
                  subheader={
                    <Skeleton variant="text" width={240} sx={{fontSize: '1rem'}}/>
                  }
                />
              </Card>
            )
          }

          {/* EVENTS LIST */
            eventsList && eventsList.length > 0 && eventsList.map((object) => (
              <Card key={object._id} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={<Avatar variant="rounded" sx={{bgcolor: '#2d6a4f', width: 56, height: 56}}>
                    <EventAvailableIcon sx={{width: 42, height: 42}}/>
                  </Avatar>}
                  action={
                    <>
                      <IconButton
                        aria-label="settings"
                        onClick={() => {
                          props.hookChildOpen(true)
                          setEvent(object)
                          setDrawerDetailEvent(true)
                        }}
                      >
                        <MoreVertIcon/>
                      </IconButton>
                    </>
                  }
                  title={
                    <Typography variant="body1" gutterBottom sx={appFont}>
                      {object.title}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="body2" gutterBottom sx={appFont}>
                      {'Programado para el: ' + moment(object.date).format('DD/MM/YYYY hh:mm a')}
                    </Typography>
                  }
                />
              </Card>))
          }
          {/* EVENT ADD BUTTON */
            eventsList && eventsList.length > 0 &&
            <Button
              variant="outlined"
              disableElevation
              sx={buttonStyleOnDrawerStyled}
              fullWidth
              onClick={() => {
                if (eventsList.length === n_events*1) {
                  props.handleSnackbar("Limite de eventos alcanzado", 'warning')
                } else {
                  props.hookChildOpen(true)
                  setDrawerAddEvent(true)
                }
              }}
            >
              {t('dialogDetailsPet.eventAddButton')}
            </Button>
          }
        </>
        {/* FILES AREA */}
        <>
          <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
            {t('dialogDetailsPet.filesTitle')}
          </Typography>
          {/* FILES ADD CARD */
            filesPet && filesPet.length === 0 &&
            <Card key={'cardaddfiles'} elevation={0} sx={cardInfo} variant="outlined">
              <CardMedia sx={mediaPic} image={filesPhoto}/>
              <CardContent>
                <Stack spacing={2}>
                  <Typography gutterBottom variant="h5" component="div" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.filesCard.title1')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.filesCard.title2')}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                    {t('dialogDetailsPet.filesCard.title3')}
                  </Typography>
                  <Button
                    variant="outlined"
                    disableElevation
                    sx={buttonStyleOnDrawerStyled}
                    fullWidth
                    onClick={() => {
                      props.hookChildOpen(true)
                      setDrawerAddFile(true)
                    }}
                  >
                    {t('dialogDetailsPet.filesCard.buttonAddFile')}
                  </Button>
                </Stack>
              </CardContent>
            </Card>}

          {
            loadingFiles &&
            Array.from({length: 2}, (_, i) =>
              <Card key={`cardfilessskeletons-${i}`} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={
                    <Skeleton variant="rounded" width={40} height={40}/>
                  }
                  action={
                    <Skeleton variant="circular" width={40} height={40}/>
                  }
                  title={
                    <Skeleton variant="text" width={160} sx={{fontSize: '1rem'}}/>
                  }
                  subheader={
                    <Skeleton variant="text" width={240} sx={{fontSize: '1rem'}}/>
                  }
                />
              </Card>
            )
          }

          {/* FILES LIST */
            filesPet && filesPet.length > 0 && filesPet.map((object) => (
              <Card key={object._id} elevation={0} sx={cardInfo} variant="outlined">
                <CardHeader
                  avatar={<Avatar variant="rounded" sx={{bgcolor: '#2d6a4f', width: 56, height: 56}}>
                    <DocumentScannerIcon sx={{width: 42, height: 42}}/>
                  </Avatar>}
                  title={
                    <Typography variant="body1" gutterBottom sx={appFont}>
                      {object.title}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="body2" gutterBottom sx={appFont}>
                      {moment(object.date).format('DD/MM/YYYY')}
                    </Typography>
                  }
                  action={
                    <>
                      <IconButton
                        aria-label="settings"
                        onClick={() => {
                          props.hookChildOpen(true)
                          setFile(object)
                          setDrawerDetailFile(true)
                        }}
                      >
                        <MoreVertIcon/>
                      </IconButton>
                    </>
                  }
                />
              </Card>
            ))
          }
          {/* FILES ADD BUTTON */
            filesPet && filesPet.length > 0 && <Button
              variant="outlined"
              disableElevation
              sx={buttonStyleOnDrawerStyled}
              fullWidth
              onClick={() => {
                if (filesPet.length === n_files*1) {
                  props.handleSnackbar("Limite de archivos alcanzado", 'warning')
                } else {
                  props.hookChildOpen(true)
                  setDrawerAddFile(true)
                }
              }}
            >
              {t('dialogDetailsPet.fileAddButton')}
            </Button>
          }
        </>
        <Button
          variant="contained"
          disableElevation
          sx={[buttonStyleOnDrawer, {mb: 4}]}
          fullWidth
          onClick={() => {
            props.hookChildOpen(true)
            setOpenDialogDeletePet(true)
          }}
        >
          {t('dialogDetailsPet.deletePetButton')}
        </Button>
        {/* DRAWER ADD EVENT */}
        <DrawerAddEvent
          hookDrawer={[drawerAddEvent, setDrawerAddEvent]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          userID={props.userID}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getEvents={getEvents}
        />
        {/* DRAWER EVENT DETAIL */}
        <DrawerDetailEvent
          hookDrawer={[drawerDetailEvent, setDrawerDetailEvent]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          event={event}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getEvents={getEvents}
        />
        {/* DRAWER PET PERFIL */}
        <DrawerDetailPetPerfil
          hookDrawer={[drawerPetPerfil, setDrawerPetPerfil]}
          hookDialog={[props.hookDialog[0], props.hookDialog[1]]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          userID={props.userID}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          listPets={props.listPets}
        />
        {/* DRAWER ADD MED */}
        <DrawerAddMed
          hookDrawer={[drawerAddMed, setDrawerAddMed]}
          hookDialog={[props.hookDialog[0], props.hookDialog[1]]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          userID={props.userID}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getMeds={getMeds}
        />
        {/* DRAWER ADD FILE */}
        <DrawerAddFile
          hookDrawer={[drawerAddFile, setDrawerAddFile]}
          hookFile={[file, setFile]}
          hookDialogFileScan={[openAddFile, setOpenAddFile]}
          hookDrawerDetailFile={[drawerDetailFile, setDrawerDetailFile]}
          hookDialog={[props.hookDialog[0], props.hookDialog[1]]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          userID={props.userID}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getFiles={getFiles}
        />
        {/* DRAWER MED DETAIL */}
        <DrawerDetailMed
          hookDrawer={[drawerDetailMed, setDrawerDetailMed]}
          hookDialog={[props.hookDialog[0], props.hookDialog[1]]}
          hookDrawerEditMed={[drawerEditMed, setDrawerEditMed]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          med={med}
          pet={props.pet}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getMeds={getMeds}
        />
        {/* DRAWER FILE DETAIL */}
        <DrawerDetailFile
          hookDrawer={[drawerDetailFile, setDrawerDetailFile]}
          hookDialogFileScan={[openAddFile, setOpenAddFile]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          pet={props.pet}
          file={file}
          userID={props.userID}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getFiles={getFiles}
          mediaFileToken={mediaFileToken}
          reloadAll={setReloadAll}
        />
        {/* DIALOG FILE */}
        <DialogFileScan
          hookDialogFileScan={[openAddFile, setOpenAddFile]}
          hookBackdrop={[props.hookBackdrop[0], props.hookBackdrop[1]]}
          hookChild={props.hookChildOpen}
          userID={props.userID}
          pet={props.pet}
          file={file}
          axiosConfig={props.axiosConfig}
          handleSnackbar={props.handleSnackbar}
          catchHandler={props.catchHandler}
          getFiles={getFiles}
          mediaFileToken={mediaFileToken}
          reloadAll={setReloadAll}
          drawerDetailFile={setDrawerDetailFile}
        />
        {/* DIALOG DELETE PET */}
        <DialogConfirm
          hookDialog={[openDialogDeletePet, setOpenDialogDeletePet]}
          title={t('dialogDetailsPet.dialogConfirm.title')}
          message={t('dialogDetailsPet.dialogConfirm.message')}
          toFunction={deletePet}
          no={t('dialogDetailsPet.dialogConfirm.cancelButton')}
          yes={t('dialogDetailsPet.dialogConfirm.deleteButton')}
        />

      </Container>

    </Dialog>)

}