import React from 'react'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  Typography,
  Container,
  //CardHeader,
  //Avatar,
  Card,
  CardMedia,
  //CardContent,
  //IconButton,
  //Link,
} from '@mui/material'

import {cardInfo, textSubtitleBold, textTitle} from '../assets/styles/styles'

//import MedicationIcon from '@mui/icons-material/Medication'
//import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
//import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
//import MoreVertIcon from '@mui/icons-material/MoreVert'

import youradhere from '../assets/images/home.adhere.jpg'

function Home() {

  const {t} = useTranslation()

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom sx={textTitle}>
        {t('home.title')}
      </Typography>
      {/*
      <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
        {t('home.subtitle')}
      </Typography>
      <Card elevation={0} sx={cardInfo} variant="outlined">
        <CardHeader
          avatar={
            <Avatar variant="rounded" sx={{bgcolor: '#d90429', width: 56, height: 56}}>
              <MedicationIcon sx={{width: 42, height: 42}}/>
            </Avatar>
          }
          action={
            <ArrowForwardIosIcon/>
          }
          title={"Medicamentos"}
          subheader={"No hay medicamentos programados aún"}
        />
      </Card>
      <Card elevation={0} sx={cardInfo} variant="outlined">
        <CardHeader
          avatar={
            <Avatar variant="rounded" sx={{bgcolor: '#03045e', width: 56, height: 56}}>
              <MonitorHeartIcon sx={{width: 42, height: 42}}/>
            </Avatar>
          }
          action={
            <ArrowForwardIosIcon/>
          }
          title={"Visitas"}
          subheader={"No hay visitas programadas aún"}
        />
      </Card>
      <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
        Cerca de mi
      </Typography>
      <Card elevation={0} sx={cardInfo} variant="outlined">
        <CardHeader
          avatar={
            <Avatar sx={{bgcolor: '#0077b6'}} aria-label="recipe">
              V
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon/>
            </IconButton>
          }
          title="Veterinaria Amanecer"
          subheader={moment().format('MM-DD-YYYY, h:mm a')}
        />
        <CardMedia
          component="img"
          height="220"
          image="https://www.ust.cl/wp-content/uploads/sites/6/2016/03/medicina-veterinaria-716x400.jpg"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Somos la mejor opción para el cuidado de tu mascota. Ofrecemos atención médica de alta calidad, desde
            revisiones hasta tratamientos complejos, con tecnología avanzada y un enfoque en la comodidad y seguridad de
            tu mascota. ¡Ven a visitarnos y comprueba por qué somos los mejores en el cuidado de tus amigos peludos!
          </Typography>
        </CardContent>
      </Card>
      */}
      <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
        {t('home.recomendations')}
      </Typography>
      <Card elevation={0} sx={cardInfo} variant="outlined">
        <CardMedia
          component="img"
          height="260"
          src={youradhere}
        />
      </Card>
    </Container>
  )
}

export default Home