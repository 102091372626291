import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/es'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose, buttonStepper,
  buttonStyleOnDrawer, customTheme,
  drawerStyle,
  textInputStyle,
  textStyleBold
} from '../assets/styles/styles'

import {
  Alert,
  Box,
  Button,
  Collapse, Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MobileStepper,
  Radio,
  RadioGroup,
  Stack,
  SwipeableDrawer,
  TextField, ThemeProvider,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DateCalendar, StaticTimePicker} from '@mui/x-date-pickers'

export const DrawerAddMed = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset, watch} = useForm({
    defaultValues: {
      initDate: moment(),
      finishDate: moment(),
      initTime: moment()
    }
  })
  const [stepAddMeds, setStepAddMeds] = useState(0)
  const [onAddMed, setOnAddMed] = useState(false)

  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('warning')

  const interval = watch('interval');

  const handleAlert = (message, severity) => {
    setAlertMessage(message)
    setAlertSeverity(severity)
    setOpenAlert(true)
  }

  const addPetMed = async (data) => {
    props.hookBackdrop[1](true)
    setOnAddMed(true)
    if (
      data.name === '' ||
      data.type === '' ||
      data.concentration === '' ||
      data.unit === '' ||
      data.interval === '' ||
      data.perHours === '' ||
      data.initDate === '' ||
      data.initTime === ''
    ) {
      handleAlert(t('drawerAddMed.addPetMed.nodata'), 'warning')
      setOnAddMed(false)
      return
    }
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('name', data.name)
    formData.append('type', data.type)
    formData.append('concentration', data.concentration)
    formData.append('unit', data.unit)
    formData.append('interval', data.interval)
    formData.append('perHours', data.perHours)
    formData.append('initDate', moment(data.initDate).toISOString())
    formData.append('finishDate', moment(data.finishDate).toISOString())
    formData.append('initTime', moment(data.initTime).toISOString())
    formData.append('displayName', data.displayName)
    formData.append('notes', data.notes)
    formData.append('status', true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/meds`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        setOnAddMed(false)
      } else {
        setOnAddMed(false)
        props.handleSnackbar(message, 'success')
        props.getMeds(props.pet._id)
        props.hookDrawer[1](false)
        setStepAddMeds(0)
        reset()
      }
    } catch (e) {
      props.catchHandler(e)
      setOnAddMed(false)
    }
    setOnAddMed(false)
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
      setStepAddMeds(0)
      reset()
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
              setStepAddMeds(0)
              reset()
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={1}>
              <form onSubmit={handleSubmit(addPetMed)}>
                {/* 1er name */}
                <Stack sx={{display: stepAddMeds === 0 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.nameMed')}
                  </Typography>
                  <Divider/>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddMed.form.name')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddMed}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                {/* 2nd type */}
                <Stack sx={{display: stepAddMeds === 1 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.typeMed')}
                  </Typography>
                  <Divider/>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    render={({field: {onChange, value}}) => (
                      <ThemeProvider theme={customTheme}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                          disabled={onAddMed}
                        >
                          <FormControlLabel
                            value={t('drawerAddMed.form.capsule')}
                            control={<Radio/>}
                            label={t('drawerAddMed.form.capsule')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value={t('drawerAddMed.form.pill')}
                            control={<Radio/>}
                            label={t('drawerAddMed.form.pill')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value={t('drawerAddMed.form.liquid')}
                            control={<Radio/>}
                            label={t('drawerAddMed.form.liquid')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value={t('drawerAddMed.form.topic')}
                            control={<Radio/>}
                            label={t('drawerAddMed.form.topic')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                        </RadioGroup>
                      </ThemeProvider>
                    )}
                  />
                </Stack>
                {/* 3rd dosis */}
                <Stack sx={{display: stepAddMeds === 2 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.concentration')}
                  </Typography>
                  <Divider/>
                  <Controller
                    name="concentration"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddMed.form.dosis')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddMed}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Divider/>
                  <Controller
                    name="unit"
                    control={control}
                    defaultValue=""
                    render={({field: {onChange, value}}) => (
                      <ThemeProvider theme={customTheme}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                          disabled={onAddMed}
                        >
                          <FormControlLabel
                            value="mg"
                            control={<Radio/>}
                            label="mg"
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value="mcg"
                            control={<Radio/>}
                            label="mcg"
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value="g"
                            control={<Radio/>}
                            label="g"
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value="ml"
                            control={<Radio/>}
                            label="ml"
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                        </RadioGroup>
                      </ThemeProvider>
                    )}
                  />
                </Stack>
                {/* 4th freq */}
                <Stack sx={{display: stepAddMeds === 3 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.frequency')}
                  </Typography>
                  <Divider/>
                  <ThemeProvider theme={customTheme}>
                    <Controller
                      name="interval"
                      control={control}
                      defaultValue=""
                      render={({field: {onChange, value}}) => (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                          disabled={onAddMed}
                        >
                          <FormControlLabel
                            value="regular"
                            control={<Radio/>}
                            label={t('drawerAddMed.form.regular')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                          <FormControlLabel
                            value="specific"
                            control={<Radio/>}
                            label={t('drawerAddMed.form.specific')}
                            sx={{'& .MuiTypography-root': appFont}}
                          />
                        </RadioGroup>
                      )}
                    />
                    <Divider/>
                  </ThemeProvider>
                  <ThemeProvider theme={customTheme}>
                    <Controller
                      name="perHours"
                      control={control}
                      defaultValue=""
                      render={({field: {onChange, value}}) => (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                          disabled={onAddMed}
                        >
                          {interval === 'regular' &&
                            <>
                              <FormControlLabel value="1" control={<Radio/>} label={t('drawerAddMed.form.hour')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="2" control={<Radio/>} label={t('drawerAddMed.form.2hours')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="4" control={<Radio/>} label={t('drawerAddMed.form.4hours')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="6" control={<Radio/>} label={t('drawerAddMed.form.6hours')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="8" control={<Radio/>} label={t('drawerAddMed.form.8hours')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="12" control={<Radio/>} label={t('drawerAddMed.form.12hours')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="24" control={<Radio/>} label={t('drawerAddMed.form.24hours')} sx={{'& .MuiTypography-root': appFont}}/>
                            </>
                          }
                          {interval === 'specific' &&
                            <>
                              <FormControlLabel value="Mo" control={<Radio/>} label={t('drawerAddMed.form.monday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="Tu" control={<Radio/>} label={t('drawerAddMed.form.tuesday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="We" control={<Radio/>} label={t('drawerAddMed.form.wednesday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="Th" control={<Radio/>} label={t('drawerAddMed.form.thursday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="Fr" control={<Radio/>} label={t('drawerAddMed.form.friday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="Sa" control={<Radio/>} label={t('drawerAddMed.form.saturday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="Su" control={<Radio/>} label={t('drawerAddMed.form.sunday')} sx={{'& .MuiTypography-root': appFont}}/>
                              <FormControlLabel value="All" control={<Radio/>} label={t('drawerAddMed.form.all')} sx={{'& .MuiTypography-root': appFont}}/>
                            </>
                          }
                        </RadioGroup>
                      )}
                    />
                  </ThemeProvider>
                </Stack>
                {/* 5th date */}
                <Stack sx={{display: stepAddMeds === 4 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.dateInit')}
                  </Typography>
                  <Divider/>
                  <ThemeProvider theme={customTheme}>
                    <Controller
                      name="initDate"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateCalendar
                            inputformat={t('drawerAddMed.form.dateFormat')}
                            value={value}
                            onChange={onChange}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </ThemeProvider>
                </Stack>
                {/* 6th finish date */}
                <Stack sx={{display: stepAddMeds === 5 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.dateEnds')}
                  </Typography>
                  <Divider/>
                  <ThemeProvider theme={customTheme}>
                    <Controller
                      name="finishDate"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateCalendar
                            inputformat={t('drawerAddMed.form.dateFormat')}
                            value={value}
                            onChange={onChange}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </ThemeProvider>
                </Stack>
                {/* 6th hour */}
                <Stack sx={{display: stepAddMeds === 6 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.hourInit')}
                  </Typography>
                  <Divider/>
                  <ThemeProvider theme={customTheme}>
                    <Controller
                      name="initTime"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <StaticTimePicker
                            slotProps={{
                              actionBar: {actions: ['clear']}
                            }}
                            value={value}
                            onChange={onChange}
                            renderinput={(params) => <TextField {...params} />}
                            localeText={{
                              clearButtonLabel: 'Resetear',
                              timePickerToolbarTitle: "Fijar hora"
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </ThemeProvider>
                </Stack>
                {/* 7th info */}
                <Stack sx={{display: stepAddMeds === 7 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerAddMed.form.aditionalInfo')}
                  </Typography>
                  <Divider/>
                  <Controller
                    name="displayName"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddMed.form.aditionalName')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddMed}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="notes"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerAddMed.form.aditionalNotes')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onAddMed}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onAddMed}
                  >
                    {t('drawerAddMed.form.buttonAdd')}
                  </Button>
                </Stack>
                <ThemeProvider theme={customTheme}>
                  <MobileStepper
                    variant="dots"
                    steps={8}
                    position="static"
                    activeStep={stepAddMeds}
                    sx={{mt: 4}}
                    nextButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddMeds((prevActiveStep) => prevActiveStep + 1)}
                        disabled={stepAddMeds === 8 - 1}
                      >
                        {t('drawerAddMed.steper.right')}
                        <KeyboardArrowRight/>
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepAddMeds((prevActiveStep) => prevActiveStep - 1)}
                        disabled={stepAddMeds === 0}
                      >
                        <KeyboardArrowLeft/>
                        {t('drawerAddMed.steper.left')}
                      </Button>
                    }
                  />
                </ThemeProvider>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                    sx={[{mb: 2}, appFont]}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}