import {
  appFont,
  boxDrawerDetail,
  buttonClose,
  buttonStyleOnDrawerStyled,
  customTheme,
  drawerStyle,
  textInputStyle,
  textSubtitleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  SwipeableDrawer,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Controller, useForm} from 'react-hook-form'

export const DrawerDetailPetPerfil = (props) => {

  const {t} = useTranslation()

  const [onEdit, setOnEdit] = useState(true)

  const {control, handleSubmit} = useForm({
    defaultValues: {
      name: props.pet.name,
      age: props.pet.age,
      description: props.pet.description,
      weight: props.pet.weight,
      allergies: props.pet.allergies,
      race: props.pet.race,
      size: props.pet.size,
      chip: props.pet.chip,
      sterilized: props.pet.sterilized
    }
  })

  const updatePet = async (data) => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('age', data.age)
    formData.append('description', data.description)
    formData.append('weight', data.weight)
    formData.append('allergies', data.allergies)
    formData.append('race', data.race)
    formData.append('size', data.size)
    formData.append('chip', data.chip)
    formData.append('sterilized', data.sterilized)
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_API}/api/pets/${props.pet._id}`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        setOnEdit(true)
        props.hookDialog[1](false)
        props.hookDrawer[1](false)
        props.listPets()
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => {
        console.log('onClose')
        props.hookChild(false)
        props.hookDrawer[1](false)
      }}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawerDetail}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              console.log('IconButton')
              props.hookChild(false)
              props.hookDrawer[1](false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={[textSubtitleBold, {mb: 2}]}
          >
            {t('drawerDetailPetPerfil.title')}
          </Typography>
          <form onSubmit={handleSubmit(updatePet)}>
            <Controller
              name="name"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.name')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="age"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.age')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.description')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  multiline
                  rows={3}
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="weight"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.weight')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="allergies"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.allergies')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  multiline
                  rows={2}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="race"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.race')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <Controller
              name="size"
              control={control}
              defaultValue={true}
              render={({field}) => (
                <TextField
                  label={t('drawerDetailPetPerfil.form.size')}
                  variant="standard"
                  size="small"
                  margin="normal"
                  type="text"
                  InputLabelProps={{style: appFont}}
                  InputProps={{style: appFont, readOnly: onEdit}}
                  sx={textInputStyle}
                  {...field}
                />
              )}
            />
            <ThemeProvider theme={customTheme}>
              <Controller
                name="chip"
                control={control}
                defaultValue={true}
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        disabled={onEdit}
                      />
                    }
                    label={t('drawerDetailPetPerfil.form.withChip')}
                    sx={{'& .MuiTypography-root': appFont}}
                  />
                )}
              />
              <Controller
                name="sterilized"
                control={control}
                defaultValue={true}
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        disabled={onEdit}
                      />
                    }
                    label={t('drawerDetailPetPerfil.form.sterilized')}
                    sx={{'& .MuiTypography-root': appFont}}
                  />
                )}
              />
            </ThemeProvider>
            {onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyled, {mt: 4}]}
                fullWidth
                onClick={() => setOnEdit(false)}
              >
                {t('drawerDetailPetPerfil.form.buttons.edit')}
              </Button>
            }
            {!onEdit &&
              <Button
                variant="outlined"
                disableElevation
                sx={[buttonStyleOnDrawerStyled, {mt: 4}]}
                fullWidth
                type="submit"
              >
                {t('drawerDetailPetPerfil.form.buttons.update')}
              </Button>
            }
          </form>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )

}