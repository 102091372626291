import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translation from './es-CL.json'

export const resources = {
  es: {
    translation
  }
};

i18next.use(initReactI18next).init({
  lng: 'es',
  resources
});