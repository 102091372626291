import React, {useState} from 'react'
import Cropper from 'react-easy-crop'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {buttonStyleOnDrawer, cardButtons} from '../assets/styles/styles'
import {Card, CardContent, Stack, Button} from '@mui/material'

export const ImageCropper = ({image, onCropDone, onCropCancel, ratio}) => {

  const {t} = useTranslation()

  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  return (
    <Card elevation={0} sx={cardButtons} variant="outlined">
      <CardContent>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Cropper
            image={image}
            aspect={ratio}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{
              containerStyle: {
                top: 170,
                left: 20,
                right: 20,
                bottom: 20
              }
            }}
          />
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyleOnDrawer}
            fullWidth
            onClick={onCropCancel}
          >
            {t('imageCropper.buttons.cancel')}
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyleOnDrawer}
            fullWidth
            onClick={() => {
              onCropDone(croppedArea)
            }}
          >
            {t('imageCropper.buttons.acept')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}