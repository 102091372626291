import React, {useEffect, useState} from 'react'

import axios from 'axios'

import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Box,
  CssBaseline,
  Alert,
  Container
} from '@mui/material'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import PetsIcon from '@mui/icons-material/Pets'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import Home from './panel.home'
import Pets from './panel.pets'
import Stories from './panel.stories'
import Account from './panel.account'

import {BottomNavAction, PaperBottomNav} from '../assets/styles/styles'

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function Panel() {

  const {t} = useTranslation()

  const [token] = useState(localStorage.getItem('token'))
  const [user_id] = useState(localStorage.getItem('userID'))

  const [contentView, setContentView] = useState(<Home/>)
  const [value, setValue] = useState(0)

  const [isTokenFound, setTokenFound] = useState(false)
  const [notificationsBrowserSupportError, setNotificationsBrowserSupportError] = useState(false)

  useEffect(() => {
    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      setNotificationsBrowserSupportError(true)
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          setTokenFound(true)
        }
      })
    }
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/serviceWorker.js')
        .then(function (registration) {
          setTokenFound(true)
          //console.log('Service Worker is registered', registration);
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BFnOLxgXDa1qlRvsLIvzujiyrLVJn5nolEI96vRi_Fg9abngaf8lfGJNc0pdffF2vi5FUdncOaFNZ3mw5e65AnM')
          })
        })
        .catch(function (error) {
          setTokenFound(false)
          //console.log('Service Worker registration or subscription failed:', error);
        });
      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array('BFnOLxgXDa1qlRvsLIvzujiyrLVJn5nolEI96vRi_Fg9abngaf8lfGJNc0pdffF2vi5FUdncOaFNZ3mw5e65AnM')
        }).then(function (subscription) {
          // The subscription was successful
          axios({
            method: 'post',
            url: 'https://api.mascotaweb.cl/api/subscribers/getPush',
            data: {
              subscription: subscription,
              user_id: user_id,
              token: token
            },
            headers: {'Content-Type': 'application/json', 'x-access-token': token},
          })
            .then(response => {
              setTokenFound(true)
              //console.log(response.data);
            })
            .catch(error => {
              //console.error(error);
            });
        }).catch(function (e) {
          setTokenFound(false)
          //console.error('Unable to subscribe to push', e);
        })
      })
    }
  }, [token, user_id])

  useEffect(() => {
    switch (value) {
      case 0:
        setContentView(<Home/>)
        return
      case 1:
        setContentView(<Pets/>)
        return
      case 2:
        setContentView(<Stories/>)
        return
      case 3:
        setContentView(<Account/>)
        return
      default:
        setContentView(<Home/>)
        return
    }
  }, [value])

  return (
    <Box sx={{pb: 7, pt: 2}}>
      <CssBaseline/>

      {contentView}

      <Container maxWidth="sm">
        {
          value === 0 ?
            isTokenFound ?
              <Alert severity="success">Notificaciones Habilitadas</Alert>
              :
              <Alert severity="warning">Notificaciones Deshabilitadas</Alert>
            : ''
        }
        {value === 0 &&
          notificationsBrowserSupportError &&
          <Alert severity="info">El navegador NO es compatible con notificaciones</Alert>
        }
      </Container>

      <Paper sx={PaperBottomNav} elevation={3}>
        <BottomNavigation
          showLabels
          sx={{backgroundColor: 'rgba(127,0,127,1)'}}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
        >
          <BottomNavigationAction sx={BottomNavAction} label={t('panel.labelHome')} icon={<LocalActivityIcon/>}/>
          <BottomNavigationAction sx={BottomNavAction} label={t('panel.labelPets')} icon={<PetsIcon/>}/>
          <BottomNavigationAction sx={BottomNavAction} label={t('panel.labelStories')} icon={<FavoriteIcon/>}/>
          <BottomNavigationAction sx={BottomNavAction} label={t('panel.labelAccount')} icon={<AccountCircleIcon/>}/>
        </BottomNavigation>
      </Paper>
    </Box>
  )
}

export default Panel