import {Controller, useForm} from 'react-hook-form'
import React, {useState, useEffect} from 'react'
import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawer,
  buttonClose,
  buttonStepper,
  buttonStyleOnDrawer, customTheme,
  drawerStyle,
  textInputStyle,
  textStyle,
  textStyleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Grid,
  MobileStepper,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
  Alert,
  IconButton,
  Collapse, ThemeProvider,
} from '@mui/material'

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import CloseIcon from '@mui/icons-material/Close'

export const DrawerRegister = (props) => {

  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm()
  const [onRegister, setOnRegister] = useState(false)
  const [stepRegister, setStepRegister] = useState(0)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('warning')

  const doRegister = async (data) => {
    props.hookBackdrop[1](true)
    setOpenAlert(false)
    setOnRegister(true)
    if (
      data.email === '' ||
      data.firstName === '' ||
      data.lastName === '' ||
      data.password === '' ||
      data.passwordValidation === ''
    ) {
      setAlertMessage(t('drawerRegister.doRegister.noInfo'))
      setAlertSeverity('warning')
      setOpenAlert(true)
      setOnRegister(false)
      props.hookBackdrop[1](false)
      return
    }
    if (data.password !== data.passwordValidation) {
      setAlertMessage(t('drawerRegister.doRegister.wrongPasswords'))
      setAlertSeverity('error')
      setOpenAlert(true)
      setOnRegister(false)
      props.hookBackdrop[1](false)
      return
    }
    if (data.password.length <= 7) {
      setAlertMessage(t('drawerRegister.doRegister.shortPassword'))
      setAlertSeverity('warning')
      setOpenAlert(true)
      setOnRegister(false)
      props.hookBackdrop[1](false)
      return
    }
    let date = new Date().toISOString()
    const formData = new FormData()
    formData.append('firstName', data.firstName)
    formData.append('lastName', data.lastName)
    formData.append('email', data.email)
    formData.append('phone', data.phone)
    formData.append('tier', 'GRATIS')
    formData.append('tierStart', date)
    formData.append('tierEnds', '')
    formData.append('password', data.password)
    formData.append('validated', false)
    formData.append('status', true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/subscribers`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }, timeout: process.env.REACT_APP_AXIOS_TIMEOUT
      })
      let error = response.data.error
      let message = response.data.message
      let object = response.data.object
      let token = response.data.token
      if (error) {
        setAlertMessage(message)
        setAlertSeverity('warning')
        setOpenAlert(true)
        setOnRegister(false)
        props.hookBackdrop[1](false)
      } else {
        localStorage.setItem('token', token)
        localStorage.setItem('userID', object.id)
        localStorage.setItem('firstName', object.firstName)
        localStorage.setItem('lastName', object.lastName)
        localStorage.setItem('email', object.email)
        localStorage.setItem('phone', object.phone)
        localStorage.setItem('tier', object.tier)
        localStorage.setItem('tierStart', object.tierStart)
        localStorage.setItem('tierEnds', object.tierEnds)
        localStorage.setItem('validated', object.validated)
        localStorage.setItem('photoId', object.photoId)
        localStorage.setItem('status', object.status)
        localStorage.setItem('n_pets', object.n_pets)
        localStorage.setItem('n_meds', object.n_meds)
        localStorage.setItem('n_events', object.n_events)
        localStorage.setItem('n_files', object.n_files)
        localStorage.setItem('n_stories', object.n_stories)
        window.location.reload()
      }
    } catch (e) {
      if (typeof e.response === 'undefined') {
        setAlertMessage(t('drawerRegister.doRegister.networkError'))
        setAlertSeverity('error')
        setOpenAlert(true)
        setOnRegister(false)
        props.hookBackdrop[1](false)
        return
      } else {
        setAlertMessage(e.response.data.message)
        setAlertSeverity('error')
        setOpenAlert(true)
        setOnRegister(false)
        props.hookBackdrop[1](false)
        return
      }
    }
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookDrawer[1](false)
      reset()
      setStepRegister(0)
      if (openAlert) setOpenAlert(false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableBackdropTransition={true}
      disableSwipeToOpen={false}
      sx={drawerStyle}
    >
      <Box sx={boxDrawer}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookDrawer[1](false)
              reset()
              setStepRegister(0)
              if (openAlert) setOpenAlert(false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Stack spacing={2}>
              <form onSubmit={handleSubmit(doRegister)}>
                <Stack sx={{display: stepRegister === 0 ? 'contents' : 'none', width: '100%'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerRegister.formTitle1')}
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formEmail')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="email"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formPhone')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="phone"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                <Stack sx={{display: stepRegister === 1 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerRegister.formTitle2')}
                  </Typography>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formName')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formLastName')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                <Stack sx={{display: stepRegister === 2 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerRegister.formTitle3')}
                  </Typography>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formPassword')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="password"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="passwordValidation"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <TextField
                        label={t('drawerRegister.formPasswordValidation')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        type="password"
                        InputLabelProps={{style: appFont}}
                        InputProps={{style: appFont}}
                        disabled={onRegister}
                        sx={textInputStyle}
                        {...field}
                      />
                    )}
                  />
                </Stack>
                <Stack sx={{display: stepRegister === 3 ? 'contents' : 'none'}} spacing={2}>
                  <Typography variant="h6" sx={textStyleBold}>
                    {t('drawerRegister.formTitle4')}
                  </Typography>
                  <Typography variant="subtitle2" sx={textStyle}>
                    {t('drawerRegister.formConfirm')}
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    disabled={onRegister}
                  >
                    {t('drawerRegister.formButtonConfirm')}
                  </Button>
                </Stack>
                <ThemeProvider theme={customTheme}>
                  <MobileStepper
                    variant="dots"
                    steps={4}
                    position="static"
                    activeStep={stepRegister}
                    sx={{mt: 4}}
                    nextButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepRegister((prevActiveStep) => prevActiveStep + 1)}
                        disabled={stepRegister === 4 - 1}
                      >
                        {t('drawerRegister.formStepRight')}<KeyboardArrowRight/>
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        sx={buttonStepper}
                        onClick={() => setStepRegister((prevActiveStep) => prevActiveStep - 1)}
                        disabled={stepRegister === 0}
                      >
                        <KeyboardArrowLeft/>{t('drawerRegister.formStepLeft')}
                      </Button>
                    }
                  />
                </ThemeProvider>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                    sx={[{mb: 2}, appFont]}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )

}