import { createTheme } from '@mui/material/styles';

const colorPrimary = '#000000'  // 2d6a4f
const colorPrimaryLight = '#000000'  //285e46
const colorPrimaryDark = '#004B23'   //004B23
const colorBackground = '#26FF8E'  // ececec
const colorWhite = '#26FF8E'     //   FFFFFF
const colorCard = '#FFFFFF'      // fdfdfe

export const backdropStyle = {
  color: colorPrimaryLight,
  zIndex: (theme) => theme.zIndex.drawer + 1400
}

export const buttonStyle = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  color: colorPrimaryDark,
  backgroundColor: colorWhite,
  borderRadius: 2,
  width: '100%',
  '&:hover': {
    backgroundColor: colorBackground,
    color: colorPrimaryDark
  }
}
export const buttonClose = {
  '&:hover': {
    color: colorPrimary
  },
}
export const buttonStyleOnDrawer = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  color: colorWhite,
  backgroundColor: colorPrimary,
  borderRadius: 2,
  width: '100%',
  '&:hover': {
    backgroundColor: colorPrimaryLight,
    color: colorWhite
  }
}
export const buttonStepper = {
  fontFamily: 'Roboto Slab',
  color: colorPrimary,
  '&:hover': {
    color: colorPrimaryLight
  }
}
export const textInputStyle = {
  width: '100%',
  '& label.Mui-focused': {
    color: colorPrimary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colorPrimary,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colorPrimary,
    },
  },
}
export const dateTimePickerStyle = {
  mt: 4,
  width: '100%',
  '& label.Mui-focused': {
    color: colorPrimary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colorPrimary,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colorPrimary,
    },
  }
}
export const buttonTextTitle = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab'
}
export const buttonStyleOnDrawerStyled = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  borderRadius: 2,
  mb: 4,
  borderColor: colorPrimary,
  color: colorPrimary,
  "&:hover": {
    backgroundColor: 'rgba(45,106,79,0.06)',
    borderColor: colorPrimary,
    color: colorPrimary
  },
}
export const buttonStyleCard = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  borderRadius: 2,
  borderColor: colorPrimary,
  color: colorPrimary,
  "&:hover": {
    backgroundColor: 'rgba(45,106,79,0.06)',
    borderColor: colorPrimary,
    color: colorPrimary
  },
}
export const buttonStyleOnDrawerStyledDetail = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  borderRadius: 2,
  borderColor: colorPrimary,
  color: colorPrimary,
  "&:hover": {
    backgroundColor: 'rgba(45,106,79,0.06)',
    borderColor: colorPrimary,
    color: colorPrimary
  },
}
export const buttonStyleOnDrawerOutlined = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  borderRadius: 2
}
export const buttonStyleOnDrawerOutlinedGroup = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  width: '100%'
}
export const buttonStyleOnDrawerStyledList = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  borderRadius: 2
}
export const textStyleWhite = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab',
  color: 'white'
}
export const textStyle = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab'
}
export const textTitle = {
  textTransform: 'inherit',
  fontFamily: 'Lobster'
}
export const textStyleBold = {
  textTransform: 'inherit',
  fontweight: 'bold',
  fontFamily: 'Roboto Slab'
}
export const textStyleBoldCenter = {
  textTransform: 'inherit',
  fontweight: 'bold',
  fontFamily: 'Roboto Slab',
  textAlign: 'center'
}
export const textSubtitleBold = {
  textTransform: 'inherit',
  fontFamily: 'Roboto Slab'
}
export const mascotappTitleWhite = {
  textTransform: 'inherit',
  fontFamily: 'Lobster',
  color: 'white'
}
export const loginBox = {
  padding: 6,
  borderRadius: 6,
  background: colorPrimary
}
export const boxDrawer = {
  position: "relative",
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  marginTop: 6,
  marginBottom: 6,
  minWidth: '40vh',
  maxWidth: '40vh'
}
export const boxDrawerDetail = {
  position: "relative",
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 6,
  marginBottom: 6,
  minWidth: '40vh',
  maxWidth: '40vh'
}
export const drawerStyle = {
  zIndex: 1301
}
export const mediaPic = {
  height: 200,
  width: 200,
  ml: 'auto',
  mr: 'auto',
  mt: 4,
  borderRadius: 6
}
export const cardInfo = {
  borderRadius: 2,
  backgroundColor: colorCard,
  mb: 2,
  borderWidth: 0
}
export const cardInfoOnDrawer = {
  borderRadius: 2,
  backgroundColor: '#f7f7f7',
  mb: 2,
  borderWidth: 0
}
export const cardButtons = {
  borderRadius: 4,
  borderColor: 'primary'
}
export const cardPet = {
  borderRadius: 2,
  backgroundColor: colorCard,
  mb: 2,
  borderWidth: 0
}
export const PaperBottomNav = {
  backgroundColor: "#ffffffba",
  backdropFilter: 'blur(16px)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0
}
export const BottomNavAction = {
  color: 'rgb(140,140,139)',
  '&.Mui-selected': {
    color: colorPrimary
  },
}
export const appFont = {
  fontFamily: 'Roboto Slab'
}
export const customTheme = createTheme({
  palette: {
    primary: {
      main: colorPrimary,
      light:  colorPrimaryLight,
      dark: colorPrimaryDark
    }
  },
  typography: {
    fontFamily: 'Roboto Slab'
  }
})