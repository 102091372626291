import React, {useEffect, useState} from 'react'

import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  Typography,
  Container,
  Button,
  Grid,
  Snackbar,
  Alert,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Skeleton,
  IconButton,
  Backdrop,
  CircularProgress
} from '@mui/material'

import {
  appFont, backdropStyle,
  buttonStyleOnDrawer,
  buttonStyleOnDrawerStyled,
  buttonTextTitle,
  cardInfo,
  cardPet,
  mediaPic,
  textSubtitleBold,
  textTitle
} from '../assets/styles/styles'


import storiesadd from '../assets/images/stories.card.jpg'
import {DialogStoriePhoto} from '../components/DialogStoriePhoto'

import MoreVertIcon from '@mui/icons-material/MoreVert'

function Stories() {

  const {t} = useTranslation()

  // Local storage items hooks
  const [token] = useState(localStorage.getItem('token'))
  const [userID] = useState(localStorage.getItem('userID'))
  const [n_stories] = useState(localStorage.getItem('n_stories'))

  // Lists of items hooks
  const [storiesList, setStoriesList] = useState({object: null, count: 0})

  const [loading, setLoading] = useState(true)

  const [backdrop, setBackdrop] = useState(false)

  const [mediaToken, setMediaToken] = useState('')

  // Snackbat hooks
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  // Dialogs fullscreens open hooks
  const [openAddStories, setOpenAddStories] = useState(false)

  // Add storie model hooks
  const [storie, setStorie] = useState('')

  const axiosConfig = {
    headers: {'content-type': 'multipart/form-data', 'x-access-token': token},
    timeout: process.env.REACT_APP_AXIOS_TIMEOUT
  }

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setOpenSnackbar(true)
  }

  const catchHandler = (e) => {
    if (typeof e.response === 'undefined') {
      handleSnackbar(t('stories.title'), 'error')
    } else {
      handleSnackbar(e.response.data.message, 'error')
    }
  }

  const getStories = async (subscriber) => {
    if (!loading) setLoading(true)
    // Get Files
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/stories/listBySubscriber/${subscriber}`, axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        //if (message === 'Without authorization token') signout()
        handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        setStoriesList(response.data.object)
        setMediaToken(response.data.mediaToken)
      }
    } catch (e) {
      catchHandler(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (storiesList.object === null) getStories(userID)
  })

  useEffect(() => {
    function onBackButtonEvent(e) {
      e.preventDefault()
      if (openAddStories) setOpenAddStories(false)
    }

    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [openAddStories]);

  return (
    <Container maxWidth="sm">

      <Backdrop sx={backdropStyle} open={backdrop}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom sx={textTitle}>
          {t('stories.title')}
        </Typography>
        {
          !storiesList &&
          storiesList.length !== 0 &&
          <Button
            variant="text"
            sx={buttonTextTitle}
            onClick={() => setOpenAddStories(true)}
          >
            {t('stories.storieAdd')}
          </Button>
        }
      </Grid>

      {/* PETS ADD CARD SKELETONS LINEA 161 height 460*/
        loading &&
        Array.from({length: 3}, (_, i) =>
          <Card key={i} elevation={0} sx={cardPet} variant="outlined">
            <Skeleton variant="rounded" height={280}/>
            <CardContent>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Skeleton variant="text" width={280} sx={{fontSize: '1rem'}}/>
                  <Skeleton variant="text" width={280} sx={{fontSize: '1rem'}}/>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Skeleton variant="circular" width={40} height={40}/>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )
      }

      {/* STORIES ADD CARD */
        storiesList &&
        storiesList.length === 0 &&
        <Card elevation={0} sx={cardInfo} variant="outlined">
          <CardMedia sx={mediaPic} image={storiesadd}/>
          <CardContent>
            <Stack spacing={2}>
              <Typography gutterBottom variant="h5" component="div" sx={textSubtitleBold}>
                {t('stories.cardStorieTitle')}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                {t('stories.cardStorieText1')}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                {t('stories.cardStorieText2')}
              </Typography>
              <Button
                variant="contained"
                disableElevation
                sx={buttonStyleOnDrawer}
                fullWidth
                onClick={() => setOpenAddStories(true)}
              >
                {t('stories.cardStorieAddButton')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      }
      {/* STORIES LIST */
        storiesList &&
        storiesList.length > 0 &&
        storiesList.map((object) => (
          <Card key={object._id} elevation={0} sx={cardPet} variant="outlined">
            {object.photoId ?
              <CardMedia
                sx={{height: 280}}
                src={object.photoId}
                component="img"
                onError={e => {
                  e.target.src = storiesadd
                }}
              />
              :
              <CardMedia
                sx={{height: 460}}
                src={storiesadd}
                component="img"
              />
            }
            <CardContent>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Typography gutterBottom variant="h5" component="div" sx={appFont}>
                    {object.title ? object.title : t('stories.storieTitle')}
                  </Typography>
                  {object.description.length > 0 && (
                    <Typography variant="body2" color="text.secondary">
                      {object.description}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      setStorie(object)
                      setOpenAddStories(true)
                    }}
                  >
                    <MoreVertIcon/>
                  </IconButton>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))
      }
      {/* STORIES ADD BUTTON */
        storiesList &&
        storiesList.length > 0 &&
        <Button
          variant="outlined"
          disableElevation
          sx={buttonStyleOnDrawerStyled}
          fullWidth
          onClick={() => {
            if (storiesList.length === n_stories*1) {
              handleSnackbar("Limite de recuerdos alcanzado", 'warning')
            } else {
              setStorie('')
              setOpenAddStories(true)
            }
          }}
        >
          {t('stories.storieAddButton')}
        </Button>
      }
      {/* DIALOG STORIES */}
      <DialogStoriePhoto
        hookDialog={[openAddStories, setOpenAddStories]}
        hookBackdrop={[backdrop, setBackdrop]}
        userID={userID}
        storie={storie}
        setStorie={setStorie}
        axiosConfig={axiosConfig}
        handleSnackbar={handleSnackbar}
        catchHandler={catchHandler}
        getStories={getStories}
        mediaToken={mediaToken && mediaToken}
      />
      {/* SNACKBAR */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity ? snackbarSeverity : 'warning'}
          sx={{width: '100%'}}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Stories