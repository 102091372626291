import React, {useEffect} from 'react'
import moment from 'moment/moment'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {
  appFont,
  boxDrawerDetail,
  buttonClose,
  buttonStyleOnDrawer,
  buttonStyleOnDrawerStyled,
  cardInfoOnDrawer,
  customTheme,
  drawerStyle,
  textStyleBoldCenter,
  textSubtitleBold
} from '../assets/styles/styles'

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  SwipeableDrawer,
  ThemeProvider,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import {FileInputFilePdf} from './FileInputFilePdf'

export const DrawerDetailFile = (props) => {

  const {t} = useTranslation()

  const deleteFile = async () => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('file', props.file._id)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files/deleteFile`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
      } else {
        props.getFiles(props.pet._id)
        props.hookDrawer[1](false)
        props.handleSnackbar(message, 'success')
      }
    } catch (e) {
      props.catchHandler(e)
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDrawer[0]) {
      props.hookChild(false)
      props.hookDrawer[1](false)
    }
  }, [props.hookDrawer[0]])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.hookDrawer[0]}
      onClose={() => props.hookDrawer[1](false)}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true,
      }}
      disableSwipeToOpen={true}
      sx={drawerStyle}
    >
      <Box sx={boxDrawerDetail}>
        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => {
              props.hookChild(false)
              props.hookDrawer[1](false)
            }}
          >
            <CloseIcon sx={buttonClose}/>
          </IconButton>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          direction="column"
        >
          <Grid item>
            <Stack spacing={1}>
              <Stack spacing={2}>
                <Typography variant="h6" sx={textStyleBoldCenter}>
                  {props.file.title}
                </Typography>
                <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
                  {t('drawerDetailFile.title')}
                </Typography>
                <Card variant="outlined" elevation={0} sx={cardInfoOnDrawer}>
                  <List sx={[{width: '100%'}, appFont]}>
                    <ListItem>
                      {props.file.description}
                    </ListItem>
                    <Divider/>
                    <ListItem>
                      {t('drawerDetailFile.date')}&nbsp;{moment(props.file.date).format(t('drawerDetailFile.dateFormat'))}
                    </ListItem>
                  </List>
                </Card>
                <Typography variant="h6" gutterBottom sx={textSubtitleBold}>
                  {t('drawerDetailFile.backups')}
                </Typography>
                <Typography variant="subtitle2" gutterBottom sx={textSubtitleBold}>
                  {t('drawerDetailFile.documents')}
                </Typography>
                <Button
                  variant="outlined"
                  disableElevation
                  sx={buttonStyleOnDrawerStyled}
                  fullWidth
                  onClick={() => {
                    //props.hookDrawer[1](false)
                    props.hookDialogFileScan[1](true)
                  }}
                >
                  {t('drawerDetailFile.docPhoto')}
                </Button>
                <Typography variant="subtitle2" gutterBottom sx={textSubtitleBold}>
                  {t('drawerDetailFile.docPdf')}
                </Typography>
                <ThemeProvider theme={customTheme}>
                  <FileInputFilePdf
                    userID={props.userID}
                    pet={props.pet._id}
                    file={props.file}
                    hookDrawer={props.hookDrawer}
                    axiosConfig={props.axiosConfig}
                    handleSnackbar={props.handleSnackbar}
                    catchHandler={props.catchHandler}
                    mediaFileToken={props.mediaFileToken}
                    drawerDetailFile={props.hookDrawer[1]}
                    reloadAll={props.reloadAll}
                  />
                </ThemeProvider>
                <Button
                  variant="outlined"
                  disableElevation
                  sx={buttonStyleOnDrawer}
                  fullWidth
                  onClick={() => deleteFile()}
                >
                  {t('drawerDetailFile.buttonDelete')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  )
}