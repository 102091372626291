import React, {useState, useEffect, useCallback} from 'react'

import '../langs/config'
import {useTranslation} from 'react-i18next'

import {ImageCropper} from './ImageCropper'

import {appFont, buttonStyleOnDrawer, cardButtons, cardPet} from '../assets/styles/styles'

import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import nofile from '../assets/images/nofile.jpg'
import axios from "axios";

import {FileInputFileScan} from './FileInputFileScan'

export const DialogFileScan = (props) => {

  const {t} = useTranslation()

  const [image, setImage] = useState('')
  const [currentPage, setCurrentPage] = useState('choose-img')
  const [imgAfterCrop, setImgAfterCrop] = useState('')

  const onCropCancelFile = useCallback(() => {
    props.hookDialogFileScan[1](false)
    setCurrentPage('choose-img')
    setImage('')
    //setDrawerDetailFile(true)
  }, [props])

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg)
    setCurrentPage('crop-img')
  }

  const deleteFilePhoto = async () => {
    props.hookBackdrop[1](true)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('file', props.file._id)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files/deletePhoto`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        //onCropCancel()
        onCropCancelFile()
      } else {
        props.handleSnackbar(message, 'success')
        //onCropCancel()
        onCropCancelFile()
        props.reloadAll(true)
        props.drawerDetailFile(false)
      }
    } catch (e) {
      props.catchHandler(e)
      //onCropCancel()
      onCropCancelFile()
    }
    props.hookBackdrop[1](false)
  }

  const onCropDone = async (imgCroppedArea) => {
    const canvasEle = document.createElement('canvas')
    canvasEle.width = imgCroppedArea.width
    canvasEle.height = imgCroppedArea.height
    const context = canvasEle.getContext('2d')
    let imageObj1 = new Image()
    imageObj1.src = image
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      )
      const dataURL = canvasEle.toDataURL('image/jpeg')
      setImgAfterCrop(dataURL)
      setCurrentPage('img-cropped')
    }
  }

  const calculateChecksum = async (base64) => {
    // Remove data URI scheme if it exists
    base64 = base64.replace(/^data:image\/[a-z]+;base64,/, "");
    // Decode base64 string to Uint8Array
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    // Calculate SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', array);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  const uploadFile = async () => {
    props.hookBackdrop[1](true)
    const checksum = await calculateChecksum(imgAfterCrop)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('pet', props.pet._id)
    formData.append('fileName', props.file._id)
    formData.append('uploadFile', imgAfterCrop)
    formData.append('base64', true)
    formData.append('checksum', checksum)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/files/uploadPhoto`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        onCropCancelFile()
      } else {
        props.handleSnackbar(message, 'success')
        //props.getFiles(props.pet._id)
        onCropCancelFile()
        props.reloadAll(true)
        props.drawerDetailFile(false)
      }
    } catch (e) {
      props.catchHandler(e)
      onCropCancelFile()
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDialogFileScan[0]) onCropCancelFile()
  }, [props.hookDialogFileScan[0]])

  return (
    <Dialog
      fullScreen
      open={props.hookDialogFileScan[0]}
      PaperProps={{
        style: {
          backgroundColor: '#f1f0f4'
        },
      }}
      sx={{zIndex: 1302}}
    >
      <AppBar sx={{
        position: 'relative',
        mb: 2,
        backgroundColor: '#2d6a4f'
      }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onCropCancelFile()}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={[{ml: 2, flex: 1}, appFont]} variant="h6" component="div">
            {t('dialogFileScan.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        {currentPage === "choose-img" ? (
          <>
            <Card elevation={0} sx={cardPet} variant="outlined">
            {props.file.photoId ?
                <CardMedia
                  src={props.file.photoId}
                  component="img"
                  onError={e => {
                    e.target.src = nofile
                  }}
                />
                :
                <CardMedia
                  src={nofile}
                  component="img"
                />
              }
            </Card>
            <FileInputFileScan
              setImage={setImage}
              onImageSelected={onImageSelected}
              onPress={deleteFilePhoto}
            />
          </>
        ) : currentPage === "crop-img" ? (
          <ImageCropper
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancelFile}
            ratio={0.71}
          />
        ) : (
          <>
            <Card elevation={0} sx={cardPet} variant="outlined">
              <CardMedia
                src={imgAfterCrop}
                component="img"
              />
            </Card>
            <Card elevation={0} sx={cardButtons} variant="outlined">
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    fullWidth
                    onClick={() => {
                      setCurrentPage("crop-img");
                    }}
                  >
                    {t('dialogFileScan.buttons.back')}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    fullWidth
                    onClick={() => {
                      setCurrentPage("choose-img");
                      setImage("");
                    }}
                  >
                    {t('dialogFileScan.buttons.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={buttonStyleOnDrawer}
                    fullWidth
                    onClick={() => uploadFile()}
                  >
                    {t('dialogFileScan.buttons.upload')}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </>
        )}
      </Container>
    </Dialog>
  )
}