import React, {useRef} from 'react'

import {
  Stack,
  Button
} from '@mui/material'

import {buttonStyleOnDrawer} from '../assets/styles/styles'

import '../langs/config'
import {useTranslation} from 'react-i18next'

export const FileInputCardPhoto = ({onImageSelected, onPress, onAdd}) => {

  const {t} = useTranslation()

  const inputRef = useRef()
  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = function (e) {
        onImageSelected(reader.result)
      }
    }
  }
  const onChooseImg = () => {
    inputRef.current.click()
  }
  return (
    <>
      <input
        type="file"
        accept="image/jpeg, image/jpg"
        ref={inputRef}
        onChange={handleOnChange}
        style={{display: "none"}}
      />
      <Stack spacing={1}>
        {onAdd &&
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyleOnDrawer}
            fullWidth
            onClick={onChooseImg}
          >
            {t('fileInputCardPhoto.buttons.upload')}
          </Button>
        }
        {!onAdd &&
          <Button
            variant="contained"
            disableElevation
            sx={buttonStyleOnDrawer}
            fullWidth
            onClick={onPress}
          >
            {t('fileInputCardPhoto.buttons.delete')}
          </Button>
        }
      </Stack>
    </>
  )
}