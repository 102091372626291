import React, {useEffect} from 'react'
import {useState} from 'react'
import WebFont from 'webfontloader'

import Login from './pages/login'
import Panel from './pages/panel'

function App() {

  const [token, setToken] = useState(() => localStorage.getItem('token') || false)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto Slab', 'Lobster']
      },
      timeout: 2000
    });
  }, []);

  if (token) {
    return <Panel />
  } else {
    return <Login setToken={setToken}/>
  }
}

export default App