import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import moment from 'moment'

import '../langs/config'
import { useTranslation } from 'react-i18next'

import { buttonStyleOnDrawer, cardButtons, cardPet, textInputStyle, textStyleBold } from '../assets/styles/styles'

import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import paws from '../assets/images/uploadPhoto.jpg'

import { ImageCropper } from './ImageCropper'
import { FileInputCardPhoto } from './FileInputCardPhoto'
import { Controller, useForm } from 'react-hook-form'

export const DialogStoriePhoto = (props) => {

  const { t } = useTranslation()

  const { control, handleSubmit } = useForm()

  const [image, setImage] = useState('')
  const [currentPage, setCurrentPage] = useState('choose-img')
  const [imgAfterCrop, setImgAfterCrop] = useState('')

  const onCropCancel = useCallback(() => {
    props.hookDialog[1](false)
    setCurrentPage('choose-img')
    setImage('')
  }, [props.hookDialog])

  const onCropCancelDetail = () => {
    props.hookDialog[1](false)
    setCurrentPage('choose-img')
    setImage('')
    props.setStorie('')
  }

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg)
    setCurrentPage('crop-img')
  }

  const deletePhoto = async () => {
    props.hookBackdrop[1](true)
    if (!props.storie._id) return
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('stories', props.storie._id)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/stories/deletePhoto`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        onCropCancelDetail()
      } else {
        props.handleSnackbar(message, 'success')
        props.getStories(props.userID)
        onCropCancelDetail()
      }
    } catch (e) {
      props.catchHandler(e)
      onCropCancelDetail()
    }
    props.hookBackdrop[1](false)
  }

  const onCropDone = async (imgCroppedArea) => {
    const canvasEle = document.createElement('canvas')
    canvasEle.width = imgCroppedArea.width
    canvasEle.height = imgCroppedArea.height
    const context = canvasEle.getContext('2d')
    let imageObj1 = new Image()
    imageObj1.src = image
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      )
      const dataURL = canvasEle.toDataURL('image/jpeg')
      setImgAfterCrop(dataURL)
      setCurrentPage('img-cropped')
    }
  }

  const calculateChecksum = async (base64) => {
    // Remove data URI scheme if it exists
    base64 = base64.replace(/^data:image\/[a-z]+;base64,/, "");
    // Decode base64 string to Uint8Array
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    // Calculate SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', array);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  const uploadPhoto = async (data) => {
    props.hookBackdrop[1](true)
    const checksum = await calculateChecksum(imgAfterCrop)
    const formData = new FormData()
    formData.append('subscriber', props.userID)
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('date', moment())
    formData.append('status', true)
    formData.append('uploadFile', imgAfterCrop)
    formData.append('base64', true)
    formData.append('checksum', checksum)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/stories/uploadPhoto`, formData, props.axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        props.handleSnackbar(message, 'error')
        onCropCancel()
      } else {
        props.handleSnackbar(message, 'success')
        props.getStories(props.userID)
        onCropCancel()
      }
    } catch (e) {
      props.catchHandler(e)
      onCropCancel()
    }
    props.hookBackdrop[1](false)
  }

  useEffect(() => {
    if (!props.hookDialog[0]) onCropCancel()
  }, [props.hookDialog])

  return (
    <Dialog
      fullScreen
      open={props.hookDialog[0]}
      onClose={() => onCropCancel()}
      PaperProps={{
        style: {
          backgroundColor: '#f1f0f4'
        },
      }}
    >
      <AppBar sx={{
        position: 'relative',
        mb: 2,
        backgroundColor: '#2d6a4f'
      }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onCropCancel()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.storie._id ?
              t('dialogStoriePhoto.titleStorie') :
              t('dialogStoriePhoto.titleStorieUpload')
            }
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        {currentPage === "choose-img" ? (
          <>
            <Card elevation={0} sx={cardPet} variant="outlined">
              {props.storie.photoId ?
                <CardMedia
                  sx={{ height: 460 }}
                  src={props.storie.photoId}
                  component="img"
                  onError={e => {
                    e.target.src = paws
                  }}
                />
                :
                <CardMedia
                  sx={{ height: 460 }}
                  src={paws}
                  component="img"
                />
              }
            </Card>
            <FileInputCardPhoto
              setImage={setImage}
              onImageSelected={onImageSelected}
              onPress={deletePhoto}
              onAdd={!props.storie._id}
            />
            {
              /*
              props.storie._id &&
              <Button
                variant="contained"
                disableElevation
                sx={[buttonStyleOnDrawer, {mt: 1}]}
                fullWidth
                onClick={deleteStorie}
              >
                {t('dialogStoriePhoto.buttonDelete')}
              </Button>
              */
            }
          </>
        ) : currentPage === "crop-img" ? (
          <ImageCropper
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
            ratio={1}
          />
        ) : (
          <>
            <Card elevation={0} sx={cardPet} variant="outlined">
              <CardMedia
                sx={{ height: 460 }}
                src={imgAfterCrop}
                component="img"
              />
            </Card>
            <Card elevation={0} sx={cardButtons} variant="outlined">
              <CardContent>
                <Stack spacing={2}>
                  <form onSubmit={handleSubmit(uploadPhoto)}>
                    <Typography variant="h6" sx={textStyleBold}>
                      {t('dialogStoriePhoto.formTitle')}
                    </Typography>
                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          label={t('dialogStoriePhoto.formTitleField')}
                          variant="outlined"
                          size="small"
                          margin="normal"
                          sx={textInputStyle}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          label={t('dialogStoriePhoto.formDescription')}
                          variant="outlined"
                          size="small"
                          margin="normal"
                          sx={textInputStyle}
                          {...field}
                        />
                      )}
                    />
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        sx={buttonStyleOnDrawer}
                        fullWidth
                        onClick={() => {
                          setCurrentPage("crop-img");
                        }}
                      >
                        {t('dialogStoriePhoto.formButtonBack')}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={buttonStyleOnDrawer}
                        fullWidth
                        onClick={() => {
                          setCurrentPage("choose-img");
                          setImage("");
                        }}
                      >
                        {t('dialogStoriePhoto.formButtonCancel')}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={buttonStyleOnDrawer}
                        fullWidth
                        type="submit"
                      >
                        {t('dialogStoriePhoto.formButtonUpload')}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </CardContent>
            </Card>
          </>
        )}
      </Container>
    </Dialog>
  )
}