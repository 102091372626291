import React, {useEffect, useState} from 'react'

import axios from 'axios'

import '../langs/config'
import {useTranslation} from 'react-i18next'

// Material UI Imports
import {
  Typography,
  Container,
  Card,
  CardContent,
  CardMedia,
  Button,
  Stack,
  Box,
  IconButton,
  Skeleton,
  Backdrop,
  CircularProgress
} from '@mui/material'

// Material UI Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

// Components Imports
import {DrawerAddPet} from '../components/DrawerAddPet'
import {DialogPetPhoto} from '../components/DialogPetPhoto'
import {DialogDetailsPet} from '../components/DialogDetailsPet'
import {SnackbarComp} from '../components/SnackbarComp'

// Styles Imports
import {
  buttonStyleOnDrawer,
  mediaPic,
  cardInfo,
  cardPet,
  textTitle,
  textStyle,
  textStyleBold,
  textSubtitleBold,
  buttonStyleOnDrawerStyled, buttonStyleCard, backdropStyle
} from '../assets/styles/styles'

// Media Imports
import petImage from '../assets/images/pets.pet.jpg'

import petPhoto1 from '../assets/images/pets.pet1.jpg'
import petPhoto2 from '../assets/images/pets.pet2.jpg'
import petPhoto3 from '../assets/images/pets.pet3.jpg'
import petPhoto4 from '../assets/images/pets.pet4.jpg'

const petPhotos = [petPhoto1, petPhoto2, petPhoto3, petPhoto4]

function Pets() {

  const {t} = useTranslation()

  // Local storage items hooks
  const [token] = useState(localStorage.getItem('token'))
  const [userID] = useState(localStorage.getItem('userID'))
  const [n_pets] = useState(localStorage.getItem('n_pets'))

  // Drawers open hooks
  const [drawerAddPet, setDrawerAddPet] = useState(false)

  // Dialogs fullscreens open hooks
  const [openAddPhoto, setAddPhoto] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)

  // Lists of items hooks
  const [petsList, setPetList] = useState({object: null, count: 0})

  const [mediaToken, setMediaToken] = useState('')

  // Objects selected hooks
  const [pet, setPet] = useState('')

  const [loading, setLoading] = useState(true)

  const [backdrop, setBackdrop] = useState(false)

  const [childOpen, setChildOpen] = useState(false)

  // Snackbat hooks
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const axiosConfig = {
    headers: {'Content-Type': 'multipart/form-data', 'x-access-token': token},
    timeout: process.env.REACT_APP_AXIOS_TIMEOUT
  }

  useEffect(() => {
    function onBackButtonEvent(e) {
      e.preventDefault();
      if (openAddPhoto) setAddPhoto(false);
      if (openDetails) {
        if (!childOpen) {
          setOpenDetails(false);
        }
      }
      if (drawerAddPet) setDrawerAddPet(false);
    }
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [openAddPhoto, openDetails, drawerAddPet, childOpen]);

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setOpenSnackbar(true)
  }

  const catchHandler = (e) => {
    if (typeof e.response === 'undefined') {
      handleSnackbar(t('pets.tokenError'), 'error')
    } else {
      handleSnackbar(e.response.data.message, 'error')
    }
  }

  const listPets = async () => {
    if (!loading) setLoading(true)
    setPetList({object: []})
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/pets/listBySubscriber/${userID}`, axiosConfig)
      let error = response.data.error
      let message = response.data.message
      if (error) {
        //if (message === 'Without authorization token') signout()
        handleSnackbar(message, 'error')
      } else {
        if (typeof (response) === 'undefined') return
        setPetList(response.data.object)
        setMediaToken(response.data.mediaToken)
      }
    } catch (e) {
      catchHandler(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (petsList.object === null) listPets()
  })

  return (
    <Container maxWidth="sm">

      <Backdrop sx={backdropStyle} open={backdrop}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Typography variant="h4" gutterBottom sx={textTitle}>
        {t('pets.title')}
      </Typography>

      {/* PETS ADD CARD SKELETONS */
        loading &&
        Array.from({length: 3}, (_, i) =>
          <Card key={`carskeleton-${i}`} elevation={0} sx={cardPet} variant="outlined">
            <Skeleton variant="rounded" height={260}/>
            <CardContent>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Skeleton variant="text"  width={240} sx={{fontSize: '1rem'}}/>
                  <Skeleton variant="text" width={240} sx={{fontSize: '1rem'}}/>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Skeleton variant="text" width={100} sx={{fontSize: '2rem'}}/>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )
      }

      {/* PETS ADD CARD */
        petsList &&
        petsList.length === 0 &&
        <Card key={'cardaddpets'} elevation={0} sx={cardInfo} variant="outlined">
          <CardMedia sx={mediaPic} image={petImage}/>
          <CardContent>
            <Stack spacing={2}>
              <Typography gutterBottom variant="h5" component="div"  sx={textSubtitleBold}>
                {t('pets.cardPetTitle')}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                {t('pets.cardPetText1')}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={textSubtitleBold}>
                {t('pets.cardPetText2')}
              </Typography>
              <Button
                variant="contained"
                disableElevation
                sx={buttonStyleOnDrawer}
                fullWidth
                onClick={() => setDrawerAddPet(true)}
              >
                {t('pets.cardPetAdd')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      }
      {/* PETS LIST */
        petsList &&
        petsList.length > 0 &&
        petsList.map((object, index) => (
          <Card key={object._id} elevation={0} sx={cardPet} variant="outlined">
            <Box position="relative">
              {object.photoId ?
                <CardMedia
                  sx={{height: 280}}
                  src={`${object.photoId}`}
                  component="img"
                  onError={e => {
                    e.target.src = petPhotos[index > 3 ? 1 : index]
                  }}
                />
                :
                <CardMedia
                  sx={{height: 280}}
                  src={petPhotos[index > 3 ? 1 : index]}
                  component="img"
                />
              }
              <Box position="absolute" top={8} right={8}>
                <IconButton
                  color="primary"
                  size="large"
                  sx={{
                    backgroundColor: '#2d6a4f', // Adjust color as needed
                    color: 'white', // Adjust color as needed
                    borderRadius: '50%',
                    '&:hover': {
                      backgroundColor: '#2d6a4f', // Adjust color as needed
                    },
                  }}
                  onClick={() => {
                    setBackdrop(true)
                    setPet(object)
                    setAddPhoto(true)
                    setBackdrop(false)
                  }}
                >
                  <PhotoCameraIcon/>
                </IconButton>
              </Box>
            </Box>
            <CardContent>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Typography variant="h4" color="#000000" component="div" gutterBottom sx={textStyleBold}>
                    {object.name}
                  </Typography>
                  {object.age.length > 0 && (
                    <Typography variant="body1" color="#000000" sx={textStyle}>
                      {object.age}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >

                    <Button
                      onClick={() => {
                        setBackdrop(true)
                        setPet(object)
                        //getPetDetails(object._id)
                        setOpenDetails(true)
                        setBackdrop(false)
                      }}
                      endIcon={<ArrowForwardIosIcon/>}
                      variant="text"                      
                      disableElevation
                      sx={{
                        ...buttonStyleCard,
                        border: '3px solid #000000' // Borde rojo de 3px
                      }}
                    >
                      {`${t('pets.cardPetListDetails')} de ${object.name}`}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))
      }

      {/* PET ADD BUTTON */
        petsList &&
        petsList.length > 0 &&
        <Button
          variant="outlined"
          disableElevation
          sx={buttonStyleOnDrawerStyled}
          fullWidth
          onClick={() => {
            if (petsList.length === n_pets*1) {
              handleSnackbar("Limite de mascotas alcanzado", 'warning')
            } else {
              setDrawerAddPet(true)
            }
          }}
        >
          {t('pets.petAddButton')}
        </Button>
      }
      {/* DIALOG PET DETAILS   LINEA 316: {t('pets.cardPetListDetails') + " de" + {object.name} }*/ }
      <DialogDetailsPet
        hookDialog={[openDetails, setOpenDetails]}
        hookBackdrop={[backdrop, setBackdrop]}
        hookChildOpen={setChildOpen}
        userID={userID}
        pet={pet}
        axiosConfig={axiosConfig}
        handleSnackbar={handleSnackbar}
        catchHandler={catchHandler}
        listPets={listPets}
      />
      {/* DIALOG PHOTO */}
      <DialogPetPhoto
        hookDialogFileScan={[openAddPhoto, setAddPhoto]}
        hookBackdrop={[backdrop, setBackdrop]}
        userID={userID}
        pet={pet}
        axiosConfig={axiosConfig}
        handleSnackbar={handleSnackbar}
        catchHandler={catchHandler}
        listPets={listPets}
        mediaToken={mediaToken}
      />
      {/* DRAWER ADD PET */}
      <DrawerAddPet
        hookDrawer={[drawerAddPet, setDrawerAddPet]}
        hookBackdrop={[backdrop, setBackdrop]}
        userID={userID}
        axiosConfig={axiosConfig}
        handleSnackbar={handleSnackbar}
        listPets={listPets}
      />
      {/* SNACKBAR */}
      <SnackbarComp
        hookSnackBar={[openSnackbar, setOpenSnackbar]}
        snackSeverity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  )
}

export default Pets